<template>
    <div>
        <v-row class="mb-1">
			<v-col class="pb-0">
                <h1 class="text-h6 primary--text text-capitalize">
                    {{$t('routes.journal-delivery-of-samples')}}
                </h1>
            </v-col>
            <v-col class="mt-1 d-flex justify-end">
                <v-btn
                    v-if="isSuperAdmin || permissionsUser.GenerateMaterials !== roleName.nothing"
                    small
                    :to="{ name: 'supervisor-remaining-samples' }"
                    color="dark-yellow white--text"
                >
                    {{$t('JournalItems.remaining-materials')}}
                </v-btn>
            </v-col>
        </v-row>
        <div class="d-flex flex-wrap justify-sm-space-between justify-end align-center">
            <div class="flex-sm-grow-0 flex-grow-1 order-sm-0 order-1">
                <v-tabs v-model="tab" height="40" grow @change="updateTableDueTab()">
                    <v-tab
                        v-for="(tab, index) in types"
                        :key="index"
                        :disabled="loading"
                        class="text-capitalize"
                    >
                        {{ tab.name }}
                    </v-tab>
                    <v-tabs-slider color="primary"></v-tabs-slider>
                </v-tabs>
                <v-divider class="mx-1"/>
            </div>
        </div>
        <!-- filter -->
		<v-row class="my-2" dense>
			<v-col cols="12" sm="6" md="3">
				<bee-date-input
					v-model="date"
					:append-icon="$i18n.locale === 'en' ? 'mdi-menu-right' : 'mdi-menu-left'"
					:prepend-icon="$i18n.locale === 'en' ? 'mdi-menu-left' : 'mdi-menu-right'"
					:label="$t('inputs.date')"
					input-format-order="DD-MM-YYYY"
					dense
					:loading="loading"
                    class="mt-3"
					@click:append="
                        needToResetShownRows = true;
                        date = $i18n.locale === 'ar' ? moment(date).add(1, 'day').format('YYYY-MM-DD') : moment(date).subtract(1, 'day').format('YYYY-MM-DD');
                        getJournal();
                    "
					@click:prepend="
                        needToResetShownRows = true;
                        date = $i18n.locale === 'ar' ? moment(date).subtract(1, 'day').format('YYYY-MM-DD') : moment(date).add(1, 'day').format('YYYY-MM-DD');
                        getJournal();
                    "
				></bee-date-input>
			</v-col>
		</v-row>
        <!-- dialog edit transaction -->
        <v-dialog v-model="dialog" width="400">
            <v-card>
                <div class="d-flex primary">
                    <v-card-title
                        class="text-uppercase flex-grow-1 text-subtitle-1 d-flex justify-center pa-0 white--text"
                    >
                        {{ $t('JournalItems.modify-transfer') }}
                    </v-card-title>
                    <!-- close button -->
                    <v-btn icon @click="dialog = false">
                        <v-icon color="white">
                            mdi-close-circle-outline
                        </v-icon>
                    </v-btn>
                </div>
                <v-card-text class="mt-3 pb-1">
                    <v-row class="mt-1">
                        <v-col cols="6" class="px-1 pt-1">
                            <v-autocomplete
                                v-model="accountId"
                                :items="allAccountsBrief"
                                item-value="accountId"
                                item-text="name"
                                outlined
                                dense
                                hide-details
                                :label="$t('inputs.account')"
                            />
                        </v-col>

                        <v-col cols="6" class="px-1 pt-1">
                            <v-text-field
                                v-model.number="quantityIn"
                                :label="$t('inputs.input-quantity')" 
                                type="number" 
                                outlined
                                dense
                                hide-details="auto"
                                :rules="!quantityOut && !quantityIn ? rules.required : []"
                            />
                        </v-col>

                        <v-col cols="6" class="px-1 pt-1">
                            <v-text-field 
                                v-model.number="quantityOut"
                                :label="$t('inputs.out-quantity')" 
                                type="number" 
                                outlined
                                hide-details="auto"
                                dense
                                :rules="!quantityIn && !quantityOut ? rules.required : []"
                            />
                        </v-col>

                        <v-col cols="6" class="px-1 pt-1">
                            <v-autocomplete
                                v-model="materialId"
                                :items="myMaterials"
                                item-value="id"
                                :item-text="(item) => `${item.remaining} - ${item.name}`"
                                :disabled="loading"
                                :label="$t('inputs.item')"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>

                        <v-col cols="6" class="px-1 pt-1">
                            <v-text-field 
                                v-model="notes"
                                :label="$t('inputs.notes')"   
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
    
                        <v-col cols="6" class="px-1 pt-1">
                            <v-text-field 
                                v-model.number="price"
                                :label="$t('inputs.price')"   
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>

                        <v-col cols="12" class="px-1 pt-1">
                            <v-autocomplete 
                                v-model="state"
                                :items="transactionState"
                                item-text="name"
                                item-value="id"
                                :label="$t('JournalItems.reason')"
                                dense
                                outlined
                                hide-details
                                :disabled="Boolean(quantityIn)"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-btn color="success" small @click="edit()" :loading="submitLoading">
                        {{$t('save')}}
                    </v-btn>
                    <v-btn color="alert-color white--text" small :disabled="submitLoading" @click="dialog = false">
                        {{$t('cancel')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <bee-handy-smart-table
            v-model="tableInputs"
            ref="table"
            :items="[]"
            :headers="headers"
            :items-per-page="50"
            :loading="loading || submitLoading"
            show-add-row
            dense
            zebra
            hot-inputs
            hide-inputs-details
            :row-highlight-expression="item => item.type === 0"
			row-highlight-expression-class="orange lighten-4"
            fixed-header
            :height="$vuetify.breakpoint.height - ($vuetify.breakpoint.smAndUp ? 190 : 220)"
            :options-scroll.sync="scrollOptions"
            pagination-on-scroll
            pagination-on-scroll-server-items
            :server-items-length="serverItemsLength"
            :disable-input-fields="submitLoading || loading"
            @keypress.enter="addItem"
            @sheet-add-clicked="addItem"
            @pagination-on-scroll:reach-last-row="getJournal"
            @click:row="goToStatement"
        >
            <!-- accounts -->
            <template v-slot:input.accountId="{ on ,attr }">
                <v-autocomplete
                   :items="tab === 0 ? [currentUserData] : allAccountsBrief"
                    item-value="accountId"
                    item-text="name"
                    v-on="on"
                    v-bind="attr"
                />
            </template>

            <!-- materialId -->
            <template v-slot:input.materialId="{ on ,attr }">
                <v-autocomplete
                    :items="myMaterials"
                    item-value="id"
                    :item-text="(item) => `${item.remaining} - ${item.name}`"
                    v-on="on"
                    v-bind="attr"
                    :disabled="loading"
                />
            </template>

            <!-- date -->
            <template v-slot:input.date="{ on ,attr }">
                <bee-date-input 
                    dense
                    v-on="on"
                    v-bind="attr"
                    label=" "
                ></bee-date-input>
            </template>

            <template v-slot:input.state="{ on ,attr }">
                <v-autocomplete
                    :items="transactionState"
                    item-text="name"
                    item-value="id"
                    dense
                    v-on="on"
                    v-bind="attr"
                    label=" "
                />
            </template>

            <template v-slot:item.actions="{ on, attr, item }">
                <tooltip text="Add transaction" right>  
                    <v-btn
                        color="success"
                        small
                        icon
                        @click="dialog = true; dataRow = item; isEdit = true"
                        v-on="on"
                        v-bind="attr"   
                    >
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </tooltip>
            </template>
        </bee-handy-smart-table>
    </div>
</template>

<script>
import rules from '@/validation rules'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import Tooltip from '@/components/Tooltip.vue'
import { roleName, transactionState } from '@/helpers/enums'
export default {
    name: 'JournalItems',

    components: { Tooltip },

    data() {
        return {
            accountId: null,
            materialId: null,
            quantityIn: null,
            quantityOut: null,
            notes: null,
            dataRow: null,
            transactionState,
            roleName,
            dialog: false,
            state: null,
            tab: 0,
            loading: false,
            submitLoading: false,
            tableInputs: {},
            rules,
            accounts: [],
            date: moment().format('YYYY-MM-DD'),
            briefAccounts: [
                [], // for staff
                [], // for customers
                [], // for external
            ],
            // pagination
            scrollOptions: {},
            serverItemsLength: 0,
            needToResetShownRows: false,
            isEdit: false,
            price: null
        }
    },

    watch: {
        dialog (val) {
            if (val) {
                this.accountId = this.dataRow.accountId
                this.materialId = this.dataRow.materialId
                this.quantityIn = this.dataRow.in
                this.quantityOut = this.dataRow.out  
                this.notes = this.dataRow.notes
            }
            if (val === false) {
                this.isEdit = false
            }
        },
        
        quantityIn (val) {
            if (val !== null) {
                this.quantityOut = null
            }
        },

        quantityOut (val) {
            if (val !== null) {
                this.quantityIn = null
            }
        }
         //  if (this.) {
        //             this.quantityOut = null
        //         } else if (this.quantityOut) {
        //             this.quantityIn = null
        //         }
    },

    computed: {
        ...mapState({
            myMaterials: state => state.materials.myMaterials,
            permissionsUser: state => state.auth.youPermissions,
            isSuperAdmin: state => state.auth.isSuperAdmin,
            employeeId: state => state.auth.userData.employeeId
        }),

        ...mapGetters({
            // getMaterialById: 'materials/getMaterialById'
        }),

        types() {
            return [
                { id: 0, name: this.$t('tabs.staff') },
                { id: 1, name: this.$t('tabs.customers') },
                { id: 2, name: this.$t('tabs.external') }
            ]
        },

        headers() {
            let headers = [
                {
                    text: this.$t('headers.account'),
                    name: 'accountId',
                    value: 'accountId',
                    setValueExpr: val => this.allAccountsBrief.find(c => c.accountId === val)?.name,
                },
                {
                    text: this.$t('headers.input-quantity'),
                    name: 'in',
                    value: 'in',
                    setInputExpr: (inputs, activeInput) => {
						if (activeInput === 'out' && inputs.out) {
							return null
						}
						return inputs.in
					},  
                    inputRules: !this.tableInputs.in && !this.tableInputs.out === null ? rules.required : [],
                    type: 'number'     
                },
                {
                    text: this.$t('headers.output-quantity'),
                    name: 'out',
                    value: 'out',
                    setInputExpr: (inputs, activeInput) => {
						if (activeInput === 'in' && inputs.in) {
							return null
						}
						return inputs.out
					},  
                    inputRules: !this.tableInputs.out && !this.tableInputs.in ? rules.required : [],
                    type: 'number'     
                },
                {
                    text: this.$t('headers.item'),
                    name: 'materialId',
                    value: 'materialId',
                    setValueExpr: val => this.myMaterials.find(c => c.id === val)?.name,
                },
                {
                    text: this.$t('headers.notes'),
                    name: 'notes',
                    value: 'notes'
                },
                {
                    text: this.$t('headers.sell-price'),
                    name: 'sell-price',
                    value: 'sellPrice'
                },
                {
                    text: this.$t('headers.date'),
                    name: 'date',
                    type: 'date',
                    value: 'date',
                    dateFormat: 'DD-MM-YYYY',
                    width: 180
                },
                {
                    text: '',
                    name: 'actions',
                    value: 'actions',
                    width: 60,
                    noInput: true,
                    noNotAvailableText: true,
                    sortable: false
                }
            ]

            if (this.tab === 0) {
                headers = [
                {
                        text: this.$t('headers.account'),
                        name: 'accountId',
                        value: 'accountId',
                        setValueExpr: val => this.allAccountsBrief.find(c => c.accountId === val)?.name,
                    },
                    {
                        text: this.$t('headers.input-quantity'),
                        name: 'in',
                        value: 'in',
                        setInputExpr: (inputs, activeInput) => {
                            if (activeInput === 'out' && inputs.out) {
                                return null
                            }
                            return inputs.in
                        },  
                        inputRules: !this.tableInputs.in && !this.tableInputs.out === null ? rules.required : [],
                        type: 'number'     
                    },
                    {
                        text: this.$t('headers.output-quantity'),
                        name: 'out',
                        value: 'out',
                        setInputExpr: (inputs, activeInput) => {
                            if (activeInput === 'in' && inputs.in) {
                                return null
                            }
                            return inputs.out
                        },  
                        inputRules: !this.tableInputs.out && !this.tableInputs.in ? rules.required : [],
                        type: 'number'     
                    },
                    {
                        text: this.$t('headers.item'),
                        name: 'materialId',
                        value: 'materialId',
                        setValueExpr: val => this.myMaterials.find(c => c.id === val)?.name,
                    },
                    {
                        text: this.$t('headers.notes'),
                        name: 'notes',
                        value: 'notes'
                    },
                    {
                        text: this.$t('headers.date'),
                        name: 'date',
                        type: 'date',
                        value: 'date',
                        dateFormat: 'DD-MM-YYYY',
                        width: 180
                    },
             
                ]
                if (this.tableInputs.out !== null) {
                    headers.push({
                        text: this.$t('headers.state'),
                        name: 'state',
                        value: 'state'
                    },
                    {
                        text: '',
                        name: 'actions',
                        value: 'actions',
                        width: 60,
                        noInput: true,
                        noNotAvailableText: true,
                        sortable: false
                    }
                )
                } else {
                    headers.push({
                        text: '',
                        name: 'actions',
                        value: 'actions',
                        width: 60,
                        noInput: true,
                        noNotAvailableText: true,
                        sortable: false
                    })
                }
            }

            return headers
        },
        currentUserData() {
            return this.tab === 0 && this.briefAccounts[0].length
                ? this.briefAccounts[0].find(c => c.id === this.employeeId) || {}
                : {};
        },

        allAccountsBrief() {
            return this.briefAccounts[this.tab];
        },
    },

    methods: {
        goToStatement(item) {
            if (this.isEdit === false) {
                this.$router.replace({
                    name: 'samples-statement',
                    query: {
                        id: item.materialId
                    }
                })
            }
        },

        updateTableDueTab() {
            this.$refs.table.resetShownRows();
            this.$refs.table.resetInputs();
            this.$router.replace({ name: 'journal-items', query: { tab: this.tab } });

            this.tableInputs.accountId = this.currentUserData.accountId;

            this.updateJournal();
        },

        async updateJournal(isFirstLoad = false) {
            this.loading = true;
            return Promise.all([
                await this.getAllAccount(),
                this.getJournal(null, true),
            ]).finally(() => {
                if (!isFirstLoad) this.loading = false;
            });
        },

        moment,

        edit () {
            this.submitLoading = true
            this.loading = true
            this.$store.dispatch('materialTransactions/updateSingleTransaction', { 
                id: this.dataRow.id,
                materialId: this.materialId, 
                accountId: this.accountId, 
                out: this.quantityOut, 
                inAmount:  this.quantityIn, 
                notes: this.state === null ? this.notes : this.transactionState.find(c => c.id === this.state).name + ' ' + this.dataRow.notes,
                state: this.state
            }).then(() => {
                this.needToResetShownRows = true
                this.getJournal(null, false)
            }).finally(() => {
                this.submitLoading = false 
                // this.tableInputs.in = null
                // this.tableInputs.out = null
                this.$refs.table.resetInputsFocus()
                this.dialog = false
            })
        },

        addItem() {
            if (this.$refs.table.validate()) {
                this.loading = true
                this.$store.dispatch('materialTransactions/singleTransaction', { 
                    materialId: this.tableInputs.materialId, 
                    accountId: this.tableInputs.accountId, 
                    out: this.tableInputs.out ? this.tableInputs.out : null, 
                    inAmount: this.tableInputs.in ? this.tableInputs.in : null, 
                    notes: this.tableInputs.notes,
                    state: this.state
                }).then(() => {
                    this.needToResetShownRows = true
                    this.getJournal(null, false)
                }).finally(() => {
                    this.submitLoading = false 
                    this.tableInputs.in = null
                    this.tableInputs.out = null
                    this.$refs.table.resetInputsFocus()
                    this.dialog = false
                })
            }
        },

        // getAccount(tab) {
        //     this.submitLoading = true
        //     this.loading = true
        //     return this.$store.dispatch('accounts/fetchAllBrief', {
        //         type: tab !== null ? tab : this.$route.query.tab
        //     }).then((data) => {
        //         this.accounts = data;
        //         this.tableInputs.accountId = this.accounts.filter(c => c.id === this.employeeId)[0]?.accountId
        //     }).finally(() => {
        //         this.submitLoading = false
        //         this.loading = false
        //     })
        // },
        getAllAccount(isFirstLoad) {
            if (this.tab === 0 && this.briefAccounts[0].length) {
                this.tableInputs.accountId =
                    this.briefAccounts[0].find(c => c.id === this.employeeId)?.accountId;
                return;
            }
            if (this.allAccountsBrief.length) return;

            this.loading = true;
            return this.$store.dispatch('accounts/fetchAllBrief', {
                type: this.tab
            }).then((data) => {
                this.briefAccounts[this.tab].push(...data);
                if (this.tab === 0) {
                    this.tableInputs.accountId = this.currentUserData.accountId
                }
            }).finally(() => {
                if (!isFirstLoad) this.loading = false;
            })
        },

        getJournal(newOptionsScroll, isFirstLoad = false) {
            let page = 1
            const { itemsPerPage } = this.scrollOptions;
            if (newOptionsScroll) page = newOptionsScroll.page || 1;
            
            if (this.needToResetShownRows) {
                this.$refs.table.resetShownRows();
                this.needToResetShownRows = false;
            }

            this.loading = true
			return this.$store.dispatch('materialTransactions/fetchStatement', {
                AccountId: this.currentUserData.accountId,   
                Date: this.date,
                Page: page,
                PerPage: itemsPerPage || 50,
                type: this.$route.query.tab,
                WithVisitTransaction: this.tab !== 0
            }).then(({ data, total }) => {
				const items = data.map(c => ({ 
                    // ...c, 
                    // in: c.in !== null ? c.in.amount : '-', 
                    // out: c.out !== null ? c.out.amount : '-',
                    // materialId: c.in !== null ? c.in.materialId : c.out.materialId,
                    // accountId: c.accountId,
                    // notes: c.description,
                    ...c, 
                    in: c.in && c.in.amount, 
                    out: c.out && c.out.amount,
                    materialId: (c.in || c.out).materialId,
                    accountId: c.accountId,
                    notes: c.description,
                }));

                this.serverItemsLength = total;
                this.$refs.table.addScrollItems(items);
			}).finally(() => {
                if (!isFirstLoad) this.loading = false
            })
		},
    },

    mounted() {
        this.tab = +this.$route.query.tab || 0;
        this.$refs.table.resetInputs({
            accountId: this.employeeId,
            date: moment().format('YYYY-MM-DD')
        })
        
        this.loading = true;
        Promise.all([
            this.updateJournal(),
            !this.myMaterials.length ? this.$store.dispatch('materials/fetchMyMaterial') : null
        ]).finally(() => {
            this.tableInputs.accountId = this.currentUserData.accountId;
            this.loading = false;
        })
    },
    metaInfo: {
        title: 'Samples journal',
        titleTemplate: '%s | Octopharma'
    }
}

</script>
