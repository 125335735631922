<template>
	<div>
		<!-- add new materials -->
		<v-dialog
			v-model="dialogAddMaterials"
            max-width="600"    
        >
            <!-- <template v-slot:default="dialog"> -->
			<v-form @submit.prevent="submit" ref="form" :disabled="submitLoading">
				<v-card>
					<div class="d-flex primary py-1">
						<v-card-title
							class="text-uppercase flex-grow-1 text-subtitle-1 justify-center pa-0 white--text"
						>
							{{ $t('Products.add-product') }}
						</v-card-title>

						<!-- close button -->
						<v-btn icon @click="dialogAddMaterials = false; $refs.form.resetValidation()">
							<v-icon color="white">
								mdi-close-circle-outline
							</v-icon>
						</v-btn>
					</div>
					<v-card-text class="pt-2 px-3 pb-0">
						<!-- dialog content -->
						<v-row dense class="ma-0">
							<!-- Product name -->
							<v-col sm="6" md="4" cols="12">
								<v-text-field
									v-model="materialData.name"
									:label="$t('inputs.product-name')"
									clearable
									:rules="rules.required"
									required
									outlined
									dense
									hide-details="auto"
								></v-text-field>
							</v-col>

							<!-- company name -->
							<v-col sm="6" md="4" cols="12">
								<v-autocomplete
									v-model="materialData.companyId"
									:items="companies"
									item-text="name"
									item-value="id"
									clearable
									required
									outlined
									dense
									:label="$t('inputs.company-name')"
									hide-details="auto"
								/>
							</v-col>
							<!-- Number -->
							<v-col sm="6" md="4" cols="12">
								<v-text-field
									v-model="materialData.number"
									:label="$t('inputs.number')"
									type="number"
									min="0"
									clearable
									outlined
									dense
									hide-details="auto"
								></v-text-field>
							</v-col>

							<!-- iscu -->
							<v-col sm="6" md="4" cols="12" align-self="center">
								<v-checkbox label="مستهلكة" v-model="materialData.consumed" hide-details class="mt-0"></v-checkbox>
							</v-col>

							<v-col sm="6" md="4" cols="12">
								<v-text-field 
									v-model.number="materialData.maxAmount"
									:label="$t('inputs.max-amount')"
									dense
									outlined
									hide-details
								/>
							</v-col>

							<v-col sm="6" md="4" cols="12">
								<v-text-field 
									v-model.number="materialData.minAmount"
									:label="$t('inputs.min-amount')"
									dense
									outlined
									hide-details
								/>
							</v-col>

							<!-- english name -->
							<v-col sm="6" md="4" cols="12">
								<v-text-field
									v-model="materialData.englishName"
									:label="$t('inputs.english-name')"
									clearable
									required
									outlined
									dense
									hide-details="auto"
								/>
							</v-col>

							<!-- code -->
							<v-col sm="6" md="4" cols="12">
								<v-text-field
									v-model="materialData.code"
									:label="$t('inputs.code')"
									clearable
									required
									outlined
									dense
									hide-details="auto"
								/>
							</v-col>

							<!-- source -->
							<v-col sm="6" md="4" cols="12">
								<v-text-field
									v-model="materialData.source"
									:label="$t('inputs.source')"
									clearable
									required
									outlined
									dense
									hide-details="auto"
								/>
							</v-col>

							<!-- pharmaceutical form -->
							<v-col sm="6" md="4" cols="12">
								<v-autocomplete
									v-model="materialData.materialFormId"
									:label="$t('inputs.category')"
									clearable
									:items="pharmaceuticalForm"
									item-text="name"
									item-value="id"
									required
									outlined
									dense
									hide-details="auto"
								></v-autocomplete>
							</v-col>

							<!-- Volume -->
							<v-col v-if="0" md="4" sm="6" cols="12">
								<v-text-field
									v-model="materialData.dose"
									:label="$t('inputs.volume')"
									clearable
									outlined
									dense
									hide-details="auto"
								></v-text-field>
							</v-col>

							<!-- Pills per box -->
							<v-col v-if="0" md="4" sm="6" cols="12">
								<v-text-field
									v-model="materialData.pillsPerBox"
									:label="$t('inputs.pills-per-box')"
									clearable
									outlined
									dense
									hide-details="auto"
								></v-text-field>
							</v-col>

							<!-- product file  -->
							<v-col sm="6" md="4" cols="12">
								<v-text-field
									v-model="materialData.leafletFilePath"
									:label="$t('inputs.leaflet-url')"
									class="align-self-center"
									:prepend-icon="materialData.leafletFilePath?'mdi-link-box-variant':'mdi-link-box-variant-outline'"
									required
									outlined
									dense
									hide-details="auto"
								></v-text-field>
							</v-col>

							<!-- Strength -->
							<v-col v-if="0" sm="3" cols="12">
								<v-text-field
									v-model="materialData.strength"
									:label="$t('inputs.strength')"
									clearable
									outlined
									dense
									hide-details="auto"
								></v-text-field>
							</v-col>

							<!-- Price -->
							<v-col sm="6" md="4" cols="12">
								<v-text-field
									v-model.number="materialData.price"
									:label="$t('inputs.price')"
									type="number"
									min="0"
									step="50"
									clearable
									outlined
									dense
									hide-details="auto"
								></v-text-field>
							</v-col>

							<!-- description & image -->
							<v-col cols="12">
								<v-row dense class="ma-0">
									<!-- image -->
									<v-col v-if="0" sm="4" cols="12">
										<div class="d-flex justify-center pb-0">
											<div class="personal-image">
												<v-img
													height="90"
													max-height="90"
													width="150"
													max-width="150"
													class="rounded"
													:src="previewPhoto || require('@/assets/placeholderProduct.png')"
													:gradient="productPhoto ? '#eee2, #eee7, #eee2' : null"
												></v-img>
												
												<div class="personal-image__actions">
													<v-btn
														v-if="productPhoto"
														icon
														width="30"
														height="30"
														min-width="0"
														color="red lighten-1"
														@click="previewPhoto = null; productPhoto = null"
													>
														<v-icon> mdi-delete </v-icon>
													</v-btn>

													<v-file-input
														v-model="productPhoto"
														class="ma-0 pa-0"
														accept="image/*"
														:prepend-icon="productPhoto ? 'mdi-image' : 'mdi-plus-circle'"
														hide-input
														@change="onImageChange"
													></v-file-input>
												</div>
											</div>
										</div>
									</v-col>
									
									<!-- description -->
									<v-col cols="12" class="px-0">
										<v-textarea
											v-model="materialData.description"
											label="Description"
											rows="3"
											no-resize
											auto-grow
											outlined
											dense
											hide-details="auto"
										></v-textarea>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions class="justify-end pt-0">
						<v-btn
							type="submit"
							:loading="submitLoading"
							:disabled="submitLoading"
							color="success"
							text
						>
							{{$t('save')}}
						</v-btn>
						<v-btn
							@click="dialogAddMaterials = false"
							text
						>
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
            <!-- </template> -->
        </v-dialog>

		<!-- vacation Dialog -->
		<v-dialog v-model="vacationDialog" width="450px">
			<v-card>
				<div class="d-flex justify-space-between py-1 primary">
					<v-card-title 
						class="justify-center flex-grow-1 text-uppercase text-subtitle-1 white--text white--text pa-0"
					>
						{{$t('NavigationDrawer.add-day-off-request')}}
					</v-card-title>

					<v-btn icon color="white" @click="vacationDialog = false">
						<v-icon> mdi-close-circle-outline </v-icon>
					</v-btn>
				</div>

				<v-card-text class="pt-6 pb-0">
					<v-row>
						<v-col cols="6" class="py-1 px-1">
							<v-autocomplete
								v-model.number="leaveData.type"
								:items="requestTypes"
								item-text="name"
								item-value="id"
								:label="$t('inputs.paid-leave')"
								outlined
								dense
								:loading="submitLoading"
								:disabled="submitLoading"
							/>
						</v-col>

						<v-col cols="6" class="py-1 px-1">
							<v-text-field
								v-model.number="leaveData.daysCount"
								:label="$t('inputs.days-count')"
								outlined
								dense
								:loading="submitLoading"
								:disabled="submitLoading"
							/>
						</v-col>

						<v-col cols="6" class="py-1 px-1">
							<bee-date-picker
								v-model="leaveData.date"
								:input-label="$t('inputs.start-date')"
								elevation="2"
								menu-picker
								dense
								hide-details
								:input-props="{
									outlined: true,
									dense: true,
									clearable: true,
								}"
								:loading="submitLoading"
								:disabled="submitLoading"
								years-over-max="3"
							></bee-date-picker>
						</v-col>

						<v-col cols="6" class="py-1 px-1">
							<v-text-field 
								v-model="leaveData.reason"
								:label="$t('inputs.reason')"
								outlined
								dense
								:loading="submitLoading"
								:disabled="submitLoading"
							/>
						</v-col>
					</v-row>
				</v-card-text>

				<v-card-actions>
					<v-spacer />
					<v-btn
						text
						color="success"
						:loading="submitLoading"
						:disabled="submitLoading"
						@click="createVacation()"
					>
						{{$t('send')}}
					</v-btn>

					<v-btn
						@click="vacationDialog = false"
						:disabled="submitLoading"
						text
					>
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-navigation-drawer
			app
			v-click-outside="closeDrawer"
			dark
			color="primary"
			:mini-variant="drawer"
			:right="$i18n.locale === 'ar'"
			permanent
			class="d-flex flex-column justify-start height"
			v-model="drawer"
			:temporary="!drawer"
		>
			<v-list dense nav class="justify-start">
				<v-list-item dense v-if="drawer">
					<v-list-item-icon>
						<v-icon color="white lighten-5" @click="drawer = !drawer">mdi-menu</v-icon>
					</v-list-item-icon>
				</v-list-item>

				<v-divider v-if="drawer" />
			
				<v-list-item dense class="ma-0 px-0">
					<template v-if="!drawer" >
						<v-img
							v-if="!drawer" 
							min-width="50"
							width="50"
							max-height="50"
							contain
							class="mb-1 d-flex justify-start filter: brightness(0%);"
							src="@/assets/new-logo (1).png"
						/>
						<h4>زرع الأسنان</h4>
					</template>
						
					<!-- close drawer -->
						<!--  -->
						<!-- src="@/assets/Ibn-alhaythem-logo.png"	 -->
						<!-- @/assets/white-logo-golden-pharma.png -->
					<v-img
						v-else
						min-width="25"		
						width="25"		
						max-height="60"	
						contain
						class="my-1"
						src="@/assets/new-logo (1).png"
					/>
						<!-- src="@/assets/Ibn-alhaythem-logo.png" -->
					
					<v-divider v-if="drawer" />
		
					<v-spacer />
					<v-list-item-action class="mx-0" >
						<v-icon color="white lighten-5" @click="drawer = !drawer">mdi-menu</v-icon>
					</v-list-item-action>
				</v-list-item>

				<v-divider />
				<!-- home -->
				<v-list-item
					:to="{ name:'home' }"
					exact
					exact-path
					dense
					active-class="white--text"
					@click="drawer = true"
				>
					<tooltip
						:text="$t('NavigationDrawer.home')"
						:disabled="!drawer"
						:right="$i18n.locale !== 'ar'"
						:left="$i18n.locale === 'ar'"
					>
						<v-list-item-icon>
							<v-icon>mdi-home</v-icon>
						</v-list-item-icon>
					</tooltip>
					<v-list-item-content>
						<v-list-item-title>{{$t('NavigationDrawer.home')}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<!-- pages -->
				<!-- Scientific office -->
				<v-list-group
					active-class="active-group white--text"
				>
					<template v-slot:activator>
						<tooltip
							:text="$t('NavigationDrawer.scientific-office')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-icon>
								<v-icon color="primary-light">mdi-bullhorn</v-icon>
							</v-list-item-icon>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.scientific-office')}}</v-list-item-title>
						</v-list-item-content>
					</template>

					<v-list-item
						v-if="isSuperAdmin || permissionsUser.MedicalVisits !== roleName.nothing"
						:to="{ name: 'visits' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.visits')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-icon>
								<v-icon>mdi-map-marker-multiple </v-icon>
							</v-list-item-icon>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.visits')}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item
						v-if="isSuperAdmin || permissionsUser.MedicalPlans !== roleName.nothing"
						:to="{ name: 'medical-rep-plan' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.med-rep-plan')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-icon>
								<v-icon>mdi-calendar</v-icon>
							</v-list-item-icon>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.med-rep-plan')}} </v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					
					<v-list-item
						v-if="isSuperAdmin || permissionsUser.Leaves !== roleName.nothing"
						:to="{ name: 'day-off-requests' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.day-off-requests')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-avatar class="pe-4 my-0">
								<v-img
									src="@/assets/sidebar-icons/calendar.png"
									contain
									max-width="20"
									width="20"
									min-width="20"
								/>
							</v-list-item-avatar>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.day-off-requests')}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					
					<!-- <v-list-item
					:to="{ name: 'supervisors' }"
					class="text-body-2 group-item"
					v-if="isAdmin"
					>
						supervisors
					</v-list-item> -->
					
					<v-list-item
						v-if="isSuperAdmin || permissionsUser.WeeklyReports !== roleName.nothing"
						:to="{ name: 'double-visits' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.reports')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-avatar class="pe-4 my-0">
								<v-img 
									src="@/assets/sidebar-icons/report.png" 
									contain
									max-width="22"
									width="22"
									min-width="22" 
								/> 
							</v-list-item-avatar>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.reports')}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<v-list-item
						:to="{ name: 'visit-frequency-report' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.med-rep-mark')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-avatar class="pe-4 my-0">
								<v-icon size="22">mdi-chart-waterfall mdi-rotate-90</v-icon>
							</v-list-item-avatar>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.med-rep-mark')}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					
					<v-list-item
						v-if="isSuperAdmin || permissionsUser.Exams !== roleName.nothing"
						:to="{ name: 'Exams' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.exams')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-avatar class="py-0 pe-4 my-0">
								<v-img 
									src="@/assets/sidebar-icons/exam.png" 
									contain
									max-width="22"
									width="22"
									min-width="22" 
								/> 
							</v-list-item-avatar>
						
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.exams')}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					
					<v-list-item
						v-if="isSuperAdmin || permissionsUser.Salaries !== roleName.nothing"
						:to="{ name: 'salaries' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.salaries')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-avatar class="py-0 pe-4 my-0">
								<v-img 
									src="@/assets/sidebar-icons/money.png" 
									contain
									max-width="22"
									width="22"
									min-width="22" 
								/> 
							</v-list-item-avatar>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.salaries')}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				
				</v-list-group>

				<!-- <v-divider v-if="!drawer" /> -->

				<v-list-group
					active-class="active-group white--text"
				>
					<template v-slot:activator>
						<tooltip
							:text="$t('NavigationDrawer.storehouse')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-icon>
								<v-icon color="primary-light">
									mdi-warehouse	
								</v-icon>
							</v-list-item-icon>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.storehouse')}}</v-list-item-title>
						</v-list-item-content>
					</template>

					<v-list-item
						class="text-body-2 white--text"
						dense
						@click="dialogAddMaterials = true; drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.delivery-materials')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-avatar class="pe-4 my-0">
								<v-img 
									src="@/assets/sidebar-icons/blood-sample.png" 
									contain
									max-width="22"
									width="22"
									min-width="22" 
								/> 
							</v-list-item-avatar>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('Products.add-product')}}</v-list-item-title>
						</v-list-item-content> 
					</v-list-item>

					<v-list-item
						:to="{ name: 'journal-delivery-of-samples' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.delivery-materials')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-avatar class="pe-4 my-0">
								<v-img 
									src="@/assets/sidebar-icons/blood-sample.png" 
									contain
									max-width="22"
									width="22"
									min-width="22" 
								/> 
							</v-list-item-avatar>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.delivery-materials')}}</v-list-item-title>
						</v-list-item-content> 
					</v-list-item>
								
					<v-list-item
						:to="{ name: 'journal-items' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.items-journal')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-avatar class="pe-4 my-0">
									<!-- src="@/assets/sidebar-icons/blood-sample.png"  -->
								<v-img 
									src="@/assets/sidebar-icons/credit.png"
									contain
									max-width="22"
									width="22"
									min-width="22" 
								/> 
							</v-list-item-avatar>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.items-journal')}}</v-list-item-title>
						</v-list-item-content> 
					</v-list-item>

					<v-list-item
						:to="{ name: 'samples-statement' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.statement')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-avatar class="pe-4 my-0">
								<v-img 
									src="@/assets/sidebar-icons/delivery (1).png" 
									contain
									max-width="22"
									width="22"
									min-width="22" 
								/> 
							</v-list-item-avatar>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.statement')}}</v-list-item-title>
						</v-list-item-content> 
					</v-list-item>

					<!-- materials -->
					<v-list-item
						:to="{ name: 'materials' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.materials')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-avatar class="pe-4 my-0">
								<v-img 
									src="@/assets/sidebar-icons/option.png" 
									contain
									max-width="22"
									width="22"
									min-width="22" 
								/>
							</v-list-item-avatar>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.materials')}}</v-list-item-title>
						</v-list-item-content> 
					</v-list-item>

					<!-- loaned -->
					<v-list-item
						:to="{ name: 'loaned-items' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.loaned-materials')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-avatar class="pe-4 my-0">
								<v-img 
									src="@/assets/sidebar-icons/option3.png" 
									contain
									max-width="22"
									width="22"
									min-width="22" 
								/> 
							</v-list-item-avatar>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.loaned-materials')}}</v-list-item-title>
						</v-list-item-content> 
					</v-list-item>

					<!-- products statement -->
					<v-list-item
						v-if="isSuperAdmin || isAreaManager"
						:to="{ name: 'products-statement' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.products-statement')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-icon class="my-0">
								<v-icon>mdi-script-text</v-icon>
							</v-list-item-icon>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.products-statement')}}</v-list-item-title>
						</v-list-item-content> 
					</v-list-item>

					<!-- minimal interface -->
					<v-list-item
						:to="{ name: 'minimal-interface' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.minimal-interface')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-avatar class="pe-4 my-0">
								<v-img 
									src="@/assets/sidebar-icons/option2.png" 
									contain
									max-width="22"
									width="22"
									min-width="22" 
								/> 
							</v-list-item-avatar>
<!-- 
							<v-img 
								src="@/assets/sidebar-icons/option3.png" 
								contain
								max-width="22"
								width="22"
								min-width="22" 
							/>  -->
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.minimal-interface')}}</v-list-item-title>
						</v-list-item-content> 
					</v-list-item>
				</v-list-group>

				<!-- Sales -->
				<v-list-group
					color="white"
					active-class="active-group"
					prepend-icon="mdi-finance"
					no-action
					v-if="0"
				>
					<template v-slot:activator>
						<v-list-item-content>
							<v-list-item-title>Sales</v-list-item-title>
						</v-list-item-content>
					</template>
					
					<v-list-item
						:to="{ name: 'sales' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						sales
					</v-list-item>
					
					<v-list-item
						:to="{ name: 'statistics' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						Sales Statistics
					</v-list-item>
				</v-list-group>

				<!-- Management -->
				<v-list-group active-class="active-group white--text">
					<template v-slot:activator>
						<tooltip
							:text="$t('NavigationDrawer.management')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-icon>
								<v-icon color="primary-light"> mdi-cogs </v-icon>
							</v-list-item-icon>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.management')}}</v-list-item-title>
						</v-list-item-content>
					</template>

					<v-list-item
						:to="{ name: 'companies' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.companies')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<!-- <v-list-item-avatar class="py-0 pe-4 my-0">
								<v-img 
									src="@/assets/sidebar-icons/suitcase.png" 
									contain
									max-width="22"
									width="22"
									min-width="22"
									class="" 
								/> 
							</v-list-item-avatar> -->
							<v-list-item-icon>
								<v-icon>
									mdi-domain
								</v-icon>
							</v-list-item-icon>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title class="">{{$t('NavigationDrawer.companies')}}</v-list-item-title>
						</v-list-item-content>					
					</v-list-item>

					<v-list-item
						:to="{ name: 'staff' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.staff')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-avatar class="py-0 pe-4 my-0">
								<v-img 
									src="@/assets/sidebar-icons/suitcase.png" 
									contain
									max-width="22"
									width="22"
									min-width="22" 
								/> 
							</v-list-item-avatar>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.staff')}}</v-list-item-title>
						</v-list-item-content>					
					</v-list-item>

					<v-list-item
						:to="{ name: 'customers' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.customers')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-icon>
								<v-icon>mdi-account-multiple</v-icon>
							</v-list-item-icon>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.customers')}}</v-list-item-title>
						</v-list-item-content> 
					</v-list-item>

					<!-- management role and permissions -->
					<v-list-item
						v-if="isSuperAdmin || permissionsUser.Roles === roleName.action"
						:to="{ name: 'Roles' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.managing-roles')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-avatar class="py-0 pe-4 my-0">
								<v-img
									width="30"
									max-width="30"
									min-width="30"
									contain
									src="@/assets/sidebar-icons/setting.png"
								/>
							</v-list-item-avatar>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.managing-roles')}}</v-list-item-title>
						</v-list-item-content> 
					</v-list-item>

					<v-list-item
						:to="{ name: 'accounts-tree' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.external-entities')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-icon>
								<v-icon>mdi-account-group</v-icon>
							</v-list-item-icon>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.external-entities')}}</v-list-item-title>
						</v-list-item-content> 
					</v-list-item>

					<v-list-item
						:to="{ name: 'products' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.products')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-icon>
								<v-icon> mdi-package-variant-closed </v-icon>
							</v-list-item-icon>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.products')}}</v-list-item-title>
						</v-list-item-content> 
					</v-list-item>

					<v-list-item
						v-if="isSuperAdmin || permissionsUser.Regions !== roleName.nothing"
						:to="{ name: 'regions-management' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.regions-management')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-icon>
								<v-icon> mdi-city-variant-outline </v-icon>
							</v-list-item-icon>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.regions-management')}}</v-list-item-title>
						</v-list-item-content> 
					</v-list-item>
				</v-list-group>
				<!--  -->

				<!-- Settings -->
				<v-list-group
					v-if="false"
					active-class="active-group white--text"
				>
					<template v-slot:activator>
						<v-list-item-icon>
							<v-icon color="primary-light">mdi-cog</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Settings</v-list-item-title>
						</v-list-item-content>
					</template>

					<v-list-item
						:to="{ name: 'users' }"
						class="text-body-2 white--text"
						disabled
						dense
						@click="drawer = true"
					>
						<tooltip
							text="Years management"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-icon class="py-0 my-0 ">
								<v-icon> mdi-account-group </v-icon>
							</v-list-item-icon>
						</tooltip>	
						
						<v-list-item-content>
							<v-list-item-title>Users</v-list-item-title>
						</v-list-item-content> 
					</v-list-item>
					
					<v-list-item
						:to="{ name: 'years' }"
						class="text-body-2 white--text"
						disabled
						dense
						@click="drawer = true"
					>
						<tooltip
							text="Years management"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-avatar class="py-0 pe-4 my-0">
								<v-img 
									src="@/assets/sidebar-icons/calendar (1).png" 
									contain
									max-width="22"
									width="22"
									min-width="22" 
								/> 
							</v-list-item-avatar>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>Years management</v-list-item-title>
						</v-list-item-content> 
					</v-list-item>
				</v-list-group>

				<!-- pages -->
				<!-- 
				<v-list-group
				color="white"
				v-for="group in groups(pagesGroup)"
				:key="group.title"
				v-model="group.active"
				active-class="active-group"
				:prepend-icon="group.icon"
				no-action
				>
					<template v-slot:activator>
						<v-list-item-content>
							<v-list-item-title v-text="group.title"></v-list-item-title>
						</v-list-item-content>
					</template>

					<v-list-item
					v-for="page in pages(group.pages)"
					:key="page.title"
					:to="{ name: page.target }"
					:disabled="page.title === 'submissions' || page.title === 'supervisors report' || page.title === 'quizzes' || page.title === 'salaries' || page.title === 'sales' || page.title === 'statistics' || page.title === 'users' || page.title === 'years management'"
					class="text-capitalize white--text"
					>
						{{page.title}}
					</v-list-item>
				</v-list-group>
				-->
				<!-- <template v-slot:append> -->
				<v-list-group 
					color="white"
					active-class="active-group"
				>
					<template v-slot:activator>
						<tooltip
							:text="$t('NavigationDrawer.my-profile')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-icon>
								<v-icon color="primary-light">mdi-file-account</v-icon>
							</v-list-item-icon>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.my-profile')}}</v-list-item-title>
						</v-list-item-content>
					</template>

					<v-list-item
						v-if="isSuperAdmin || permissionsUser.MedicalPlans !== roleName.nothing"
						:to="{ name: 'administrative-visit' }"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.my-visits')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-icon>
								<v-icon>mdi-map-marker </v-icon>
							</v-list-item-icon>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.my-visits')}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<v-list-item
						v-if="0"
						:to="{ 
							name: 'weekly-report',
							query: {
								employeeId: userData.employeeId
							}
						}"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.my-report')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-avatar class="pe-4 my-0">
								<v-icon size="22">mdi-file</v-icon>
							</v-list-item-avatar>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.my-report')}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<v-list-item
						:to="{ 
							name: 'administrative-plan',
							params: {
								id: userData.employeeId
							}
						}"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.my-plan')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-avatar class="pe-4 my-0">
								<v-icon size="22">mdi-file</v-icon>
							</v-list-item-avatar>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.my-plan')}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<!-- <v-list-item
						v-if="permissionsUser.WeeklyReports === roleName.action"
						:to="{ 
							name: 'weekly-report',
							query: {
								employeeId: userData.employeeId
							}
						}"
						class="text-body-2 white--text"
						dense
						@click="drawer = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.my-report')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-avatar class="pe-4 my-0">
								<v-icon size="22">mdi-file</v-icon>
							</v-list-item-avatar>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.my-report')}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item> -->

					<v-list-item
						class="text-body-2 white--text"
						dense
						@click="drawer = true; vacationDialog = true"
					>
						<tooltip
							:text="$t('NavigationDrawer.day-off-request')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-avatar class="pe-4 my-0">
								<v-icon size="22"> mdi-calendar </v-icon>
							</v-list-item-avatar>
						</tooltip>
						<v-list-item-content>
							<v-list-item-title>{{$t('NavigationDrawer.day-off-request')}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					
					<v-list-item dense @click="logout(); drawer = true">
						<tooltip
							:text="$t('NavigationDrawer.logout')"
							:disabled="!drawer"
							:right="$i18n.locale !== 'ar'"
							:left="$i18n.locale === 'ar'"
						>
							<v-list-item-icon>
								<v-icon>mdi-logout</v-icon>
							</v-list-item-icon>
						</tooltip>						

						<v-list-item-content class="text-body-2">
							{{$t('NavigationDrawer.logout')}}
						</v-list-item-content>
					</v-list-item>
				</v-list-group>
				<!-- </template> -->
			</v-list>

			<div v-if="!drawer && $vuetify.breakpoint.mdAndUp">
				<div class="d-flex justify-center">
					<div>
						<v-img
							src="@/assets/photo_2023-08-31_17-34-48.png"
							width="150px"
						/>
					</div>
				</div>
				<span
					class="d-md-block white--text overline font-weight-light text-center"
					style="margin-left:35px; width: 180px; font-size:9px !important"
				>
					<template v-if="!drawer && $vuetify.breakpoint.mdAndUp">
						Powered by 
						<a
							href="https://beetronix.com"
							target="_blank"
							class="white--text text-decoration-none overline text-caption font-weight-light"
							style="font-size: 10px"
						>
						</a>
						Beetronix
					</template>
					<div v-else>Octopharma</div>
				</span>
			</div>
		</v-navigation-drawer>
	</div>
</template>	

<script>
import { mapGetters, mapState } from 'vuex'
import Tooltip from '../components/Tooltip'
import { roleName, requestTypes } from '../helpers/enums'
import rules from '@/validation rules'
export default {
	name: 'navigation-drawer',

	watch: {
		vacationDialog (val) {
			if (val === false) {
				this.leaveData.type = null;
				this.leaveData.daysCount = null;
				this.leaveData.date = null;
				this.leaveData.reason = null
			}
		}
	},

	components: {
		Tooltip
	},

	data(){
		return {
			roleName,
			rules,
			dialogAddMaterials: false,
			vacationDialog: false,
			leaveData: {
				type: null,
				daysCount: null,
				date: null,
				reason: null
			},
			submitLoading: false,
			requestTypes,
			pagesGroup: [
				{
					icon: 'mdi-bullhorn',
					pages: [
						{ title: 'visits', target:'visits' },
						{ title: 'dayoffrequests', target:'day-off-requests' },
						{ title: 'medical rep', target:'medical-rep' },
						{ title: 'supervisor', target:'supervisors', justForAdmin: true },
						{ title: 'supervisors report', target:'supervisors-reports', justForAdmin: true },
						{ title: 'samples', target:'samples' },
						{ title: 'customers', target:'customers' },
						{ title: 'quizzes', target:'quizzes', justForAdmin: true },
						{ title: 'salaries', target:'salaries' },
						{ title: 'Regions Management', target:'regions-management', justForAdmin: true },
					],
					title: 'Scientific office',
				},
				{
					icon: 'mdi-finance',
					active: false,
					pages: [
						{ title: 'products', target:'products' },
						{ title: 'sales', target:'sales', justForAdmin: true },
						{ title: 'statistics', target:'statistics', justForAdmin: true },
					],
					title: 'Sales',
				},
				{
					icon: 'mdi-cog',
					pages: [
						{ title: 'users', target:'users', justForAdmin: true },
						{ title: 'years management', target:'years', justForAdmin: true },
					],
					title: 'Settings',
					justForAdmin: true
				},
			],
			drawer: false,
			materialData:{
				name: null,
				materialFormId: null,
				dose: null,
				strength: null,
				pillsPerBox: null,
                number: null,
                price: null,
                description: null,
                leafletFilePath: null,
                // specialityIds: []
                companyId: null,
                code: null,
                englishName: null,
                consumed: true,
                maxAmount: 0,
                minAmount: 0
            },
		}
	},
	/*
	methods:{
		groups(groups){
			var filterGroups = [];
			groups.forEach(group => {
				if (group.justForAdmin){
					if (this.getUserType === 'admin') filterGroups.push(group);
				}
				else filterGroups.push(group)
			});

			return filterGroups;
		},

		pages(pages){
			var filterPages = [];
			pages.forEach(page => {
				if (page.justForAdmin){
					if (this.getUserType === 'admin') filterPages.push(page);
				}
				else filterPages.push(page)
			});

			return filterPages;
		},
	},
	*/

	computed:{
		...mapState({
			permissionsUser: state => state.auth.youPermissions,
			isSuperAdmin: state => state.auth.isSuperAdmin,
			userData: state => state.auth.userData,
			companies: state => state.companies.companies,
			pharmaceuticalForm: state => state.materialForms.materialForms
		}),
		...mapGetters({
			isAdmin: 'auth/isAdmin',
		}),

		isAreaManager() {
			const role = this.userData.role;
			if (role) return role.name.toLowerCase().includes('area manager');
			else return false;
		}
	},

	methods: {
		logout(){
            this.$store.dispatch('auth/logout');
            this.$router.replace({ name:'login' });
        },

		closeDrawer() {
			if (!this.drawer) {
				this.drawer = true;
			}
		},

		createVacation() {
            this.submitLoading = true
            this.$store.dispatch('leaveManagement/create', { 
                date: this.leaveData.date, 
                days: this.leaveData.daysCount,
                type: this.leaveData.type,
                reason: this.leaveData.reason
            }).then(() => {
               this.vacationDialog = false
            }).finally(() => {
                this.submitLoading = false
            })
        },
		submit(){
			if (this.$refs.form.validate()){
				this.submitLoading = true;
				this.$store.dispatch('materials/create', {
					data: this.materialData
				})
				.then(() => {
					const message = this.$t('Products.product-is-added')
					this.dialogAddMaterials = false;
				})
				.finally(() => {
					this.submitLoading = false;
				})
			}
		}
	},

	created () {
		Promise.all([
			this.$store.dispatch('companies/fetchAll'),
			this.$store.dispatch('materialForms/fetchAll')
		])
	}
}
</script>

<style>
.active-group{
	/* border-left: 3px solid #f57f20; */
	/* color: white !important */
	/* color: #a0ecff; */
	border-left: 3px solid white;
	/* color: white !important */
	color: yellow;
	
}

.v-navigation-drawer__content{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
</style>