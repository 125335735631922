<template>
    <div>
        <v-row>
            <v-col class="pb-0">
                <h1 class="text-h6 primary--text text-capitalize">
                    {{$t('routes.materials-statement')}}
                </h1>
            </v-col>
        </v-row>
        
        <!-- filters -->
        <v-row class="mt-1">
            <!-- type -->
            <v-col cols="6" md="2" sm="2" align-self="center" class="px-0">
                <v-autocomplete
                    v-model="typeAccount"
                    :items="type"
                    item-text="name"
                    item-value="accountId"
                    :label="$t('inputs.type')"
                    outlined
                    dense
                    hide-details="auto"
                    @change="getName()"
                    :loading="loading"
                    :disabled="submitLoading || loading"
                    prepend-icon="mdi-account-tie"
                 />
            </v-col>

            <!-- employee -->
			<v-col 
				cols="6" 
				lg="2" 
				md="3" 
				sm="4" 
				align-self="center" 
				:class="{
    				'py-0': $vuetify.breakpoint.lgAndUp
    			}"
			>
                <v-autocomplete
                    v-model="name"
                    :items="typeAccount !== null ? names : []"
                    item-text="name"
                    item-value="accountId"
                    :label="$t('inputs.name')"
                    outlined
                    dense
                    hide-details
                    :loading="loading || submitLoading"
                    :disabled="submitLoading || loading"
               />
            </v-col>

            <!-- material -->
            <v-col cols="6" sm="4" md="3" lg="2" align-self="center">
                <v-autocomplete
                    v-model="item"
                    :label="$t('inputs.product-name')"
                    :items="materials"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    hide-details
                    :loading="loading"
                    :disabled="submitLoading || loading"
                />
            </v-col>

            <!-- start date -->
            <v-col cols="6" lg="2" md="3" sm="3" align-self="center">
                <bee-date-picker
                    v-model="startDate"
                    menu-picker
                    dense
                    hide-details
                    :input-props="{
                        dense: true,
                        outlined: true,
                        prependIcon: 'mdi-calendar'
                    }"
                    :input-label="$t('inputs.start-date')"
                    :loading="loading"
                    :disabled="submitLoading || loading"
                ></bee-date-picker>
            </v-col>

            <!-- end date -->
            <v-col cols="6" lg="2" md="3" sm="3" align-self="center">
                <bee-date-picker
                    v-model="endDate"
                    menu-picker
                    dense
                    hide-details
                    :input-props="{
                        dense: true,
                        outlined: true,
                        prependIcon: 'mdi-calendar',
                        loading: loading
                    }"
                    :input-label= "$t('inputs.end-date')"
                    :disabled="submitLoading || loading"
                ></bee-date-picker>
            </v-col>

            <!-- Pharmaceutical form -->
            <v-col v-if="0" cols="6" md="3" sm="4" align-self="center">
                <v-autocomplete
                    :items="materialForms"
                    item-text="name"
                    item-value="id"
                    :label="$t('inputs.category')"
                    dense
                    outlined
                    hide-details
                    :return-object="false"
                />
            </v-col>

            <v-col cols="6" md="auto" sm="4" align-self="center">
                <v-btn-toggle
                    v-model.number="isConsumed"
                    mandatory
                    dense
                    class="mb-1"
                    color="primary"
                    @change="resetAllFilter = false;needToResetItems = true; fetchAll()"
                    :disabled="submitLoading || loading"
                    :loading="loading"
                >
                    <v-btn :disabled="loading"> {{$t('consumed')}} </v-btn>
                    <v-btn :disabled="loading">{{ $t('not-consumed') }}</v-btn>
                </v-btn-toggle>
            </v-col>

            <!-- from price -->
            <v-col md="2" sm="6" cols="12" align-self="center">
                <v-text-field 
                    v-model.number="fromPrice"
                    :label="$t('inputs.from-sell-price')"
                    type="number"
                    dense
                    outlined
                    hide-details
                    :disabled="submitLoading || loading"
                    :loading="loading"
                />
            </v-col>

            <!-- to price -->
            <v-col md="2" sm="6" cols="12" align-self="center">
                <v-text-field 
                    v-model.number="toPrice"
                    :label="$t('inputs.to-sell-price')"
                    type="number"
                    dense
                    outlined
                    hide-details
                    :disabled="submitLoading || loading"
                    :loading="loading"
                />
            </v-col>

            <v-col md="2" sm="6" cols="12" align-self="center" >
                <v-autocomplete 
                    v-model="state"
                    :items="transactionState"
                    item-text="name"
                    item-value="id"
                    :label="$t('SamplesStatement.state')"
                    dense
                    outlined
                    hide-details
                    :disabled="submitLoading || loading"
                    :loading="loading"
                />
            </v-col>

            <!-- SellPriceFrom, SellPriceTo, State -->

			<v-col class="d-flex py-0" align-self="center">
				<v-btn
					color="success"
					small
					class="mx-2"
                    :loading="loading"
                    :disabled="submitLoading || loading || isAllFieldsEmpty"
                    @click="resetAllFilter = false;needToResetItems = true; fetchAll();"
				>
					{{$t('search')}}
				</v-btn>

				<v-btn
					color="alert-color white--text"
					small
                    :loading="loading"
                    :disabled="submitLoading || loading"
                    @click="needToResetItems = true; reset(); fetchAll()"
				>
                    {{$t('reset')}}
				</v-btn>

                <v-btn
                    small
                    color="green lighten-1 white--text"
                    @click="importExcel"
                    class="mx-1"
                    :loading="loadingExcel"
                    :disabled="loadingExcel"
                >
                    <v-icon> mdi-file-table-outline </v-icon>
                    {{ $t('export-excel') }}
                </v-btn>
            </v-col>
        </v-row>

        <div
            v-if="resetAllFilter && !$route.query.id"
            class="d-flex flex-column justify-center align-center"
            :style="{ height: `${$vuetify.breakpoint.height - 90}px` }"
        >
            <v-progress-circular
                v-if="loading"
                color="primary"
                indeterminate
                size="44"
                width="4"
            />
                <template v-else>
                    <v-icon size="60" class="mb-1">mdi-invoice-list</v-icon>
                    <span class="text-h6">{{$t('SamplesStatement.please-select-a-filter')}}</span>
                </template>
        </div>

		<!-- table -->
		<bee-handy-table
            v-if="!resetAllFilter || $route.query.id"
            ref="table"
			:items="[]"
			:headers="headers"
			dense
			zebra
			class="mt-6"
            hide-default-footer
            :loading="loading"
            height="420"
            fixed-header
            pagination-on-scroll
            :pagination-on-scroll-auto-reset-shown="false"
            pagination-on-scroll-server-items
            :items-per-page="20"
            :options-scroll.sync="scrollOptions"
            :server-items-length="serverItemsLength"
            @pagination-on-scroll:reach-last-row="fetchAll"
            :shown-rows-text="$t('shown-rows')"
		></bee-handy-table>
    </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'
import { transactionState } from '@/helpers/enums'
export default {
    data () {
        return {
            transactionState,
            state: null,
            toPrice: null,
            fromPrice: null,
            isConsumed: 0,
            loadingExcel: false,
            loading: false,
            resetAllFilter: true,
            items: [],
			entities: [],
            item: null,
            startDate: null,
            endDate: null,
            name: null,
            typeAccount: null,
            names: [],
            submitLoading: false,
            summary: [],
            needToResetItems: false,
            scrollOptions: {},
            serverItemsLength: 0,
            accountsTypes: []
        }
    },

    computed: {
        ...mapState({ 
            materials: state => state.materials.materials,
            materialForms: state => state.materialForms.materialForms,
            supervisors: state => state.supervisors.supervisors,
            medicalreps: state => state.medicalreps.medicalreps,
            isSuperAdmin: state => state.auth.isSuperAdmin,
            // summary: state => state.materialTransactions.summary,
            EmployeeId: state => state.auth.userData.employeeId,
            accounts: state => state.accounts.accounts,
        }),

        ...mapGetters({
            isAdmin: 'auth/isAdmin',
            getMaterialById: 'materials/getMaterialById',
            getAccountById: 'accounts/getAccountById'
        }),

        isAllFieldsEmpty(){
            return this.typeAccount === null && this.name === null && this.item === null && this.startDate === null && this.endDate === null && Boolean(this.isConsumed) && this.fromPrice && this.toPrice;
        },

        type() {
            return [
				{ accountId: 1, name: this.$t('SamplesStatement.customers'), },
				{ accountId: 2, name: this.$t('SamplesStatement.external-entities'), },
				{ accountId: 0, name: this.$t('SamplesStatement.staff'), }
			]
        },

        headers() {        
            return [
                {
                    text: this.$t('headers.account'),
                    name: 'accountId',
                    value: 'accountId',
                    setValueExpr: val => this.accountsTypes.find(c => c.accountId === val)?.name
                },
                {
                    text: this.$t('headers.input-quantity'),
                    name: 'Input-quantity',
                    value: 'in',
                    setValueExpr: val => val !== null ? val.amount : '-'
                },
                {
                    text: this.$t('headers.output-quantity'),
                    name: 'output-quantity',
                    value: 'out',
                    setValueExpr: val => val !== null ? val.amount : '-'
                },
                {
                    text: this.$t('headers.notes'),
                    name: 'description',
                    value: 'description'
                },
                {
                    text: this.$t('headers.material'),
                    name: 'material',
                    value: 'materialId',
                    setValueExpr: val => this.getMaterialById(val)?.name
                },
                {
                    text: this.$t('headers.date'),
                    name: 'date',
                    value: 'date',
                    setValueExpr: val => moment(val).format('YYYY-MM-DD')
                },
                // {
                //     text: 'balance',
                //     name: 'balance',
                //     value: 'balance'
                // }
            ]
        }
    },

	methods: {	
        moment,
        reset() {
            this.item = null
            this.startDate = null
            this.endDate = null
            this.name = null
            this.typeAccount = null
            this.resetAllFilter = true
            this.isConsumed = 0
            this.fromPrice = null
            this.toPrice = null
            this.state = null
        },

        getName() {
            this.submitLoading = true
            this.$store.dispatch('accounts/fetchAllBrief', { type: this.typeAccount }).then((data) => {
                // console.log(data)
                this.names = data
                this.submitLoading = false
            }).finally(() => {
                this.submitLoading = false
            })
        },

        getAccounts() {
            this.$store.dispatch('accounts/fetchAllBrief', {}).then((data) => {
                this.accountsTypes = data  
            })
        },

        fetchAll(newOptionsScroll) {
            this.loading = true;
            let page = 1;

            const { itemsPerPage } = this.scrollOptions;

            if (newOptionsScroll) {
                page = newOptionsScroll.page;
            }
            this.$store.dispatch('materialTransactions/fetchStatement', { 
                type: this.typeAccount,
                date: this.moment().format('YYYY-MM-DD'), 
                MaterialId: this.item, 
                FromDate: this.startDate, 
                ToDate: this.endDate, 
                AccountId: this.name, 
                Page: page, 
                PerPage: itemsPerPage,
                WithVisitTransaction: true,
                IsConsumed: this.isConsumed === 0,
                SellPriceFrom: this.fromPrice, 
                SellPriceTo: this.toPrice,
                State: this.state
            }).then(({ data, total }) => {
                if (this.needToResetItems) { 
                    this.$refs.table.resetShownRows();
                    this.needToResetItems = false;
                }
                const entities = data.map(c => ({ 
                    ...c, 
                    materialId: c.in ? c.in.materialId : c.out.materialId
                }));
                this.$refs.table.addScrollItems(entities)
                this.entities = entities
                this.serverItemsLength = total;
            }).finally(() => {
                this.loading = false;
            })
        },

        importExcel() {
            this.loadingExcel = true
            this.$store.dispatch('exporter/fetchStatementExport', { 
                MaterialId: this.item, 
                FromDate: this.startDate, 
                ToDate: this.endDate, 
                AccountId: this.name, 
                Type: this.typeAccount 
            }).finally(() => {
                this.loadingExcel = false
            })
        },
	},

    created () {
        this.loading = true
        Promise.all([
            this.getAccounts(),
            this.$store.dispatch('materials/fetchAll'),
            this.$store.dispatch('materialForms/fetchAll'),
            this.$store.dispatch('medicalreps/fetchAll', true),
            // this.$store.dispatch('accounts/fetchAll', {}),
        ]).then(() => {
            if (this.$route.query.id) {
                this.item = this.$route.query.id
                this.needToResetItems = true; 
                this.fetchAll()
            } else {
                this.fetchAll()
            }
        }).finally(() => {
            this.loading = false
        })
    } 

}
</script>

<style scoped>

</style>