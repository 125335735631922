export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const GET_USER_DATA = 'GET_USER_DATA';

export const STORE_CITIES = 'STORE_CITIES';

export const STORE_REGIONS = 'STORE_REGIONS';
export const CLEAR_REGIONS = 'CLEAR_REGIONS';
export const STORE_REGIONS_ALL = 'STORE_REGIONS_ALL';

export const STORE_SECTORS = 'STORE_SECTORS';
export const STORE_SECTOR_CITY = 'STORE_SECTOR_CITY';
export const CLEAR_SECTOR_CITY = 'CLEAR_SECTOR_CITY';
export const STORE_SECTORS_FILTERED = 'STORE_SECTORS_FILTERED';

export const STORE_EMPLOYEES = 'STORE_EMPLOYEES';

export const STORE_MEDICALREPS = 'STORE_MEDICALREPS';
export const STORE_MEDICALREPS_FILTERED = 'STORE_MEDICALREPS_FILTERED';

export const STORE_SUPERVISOR = 'STORE_SUPERVISOR';

export const STORE_MATERIALS = 'STORE_MATERIALS';
export const STORE_MATERIALS_FORM = 'STORE_MATERIALS_FORM';

export const STORE_SUMMARY = 'STORE_SUMMARY';
export const STORE_TRANSACTINOS = 'STORE_TRANSACTINOS';

export const STORE_VISITS = 'STORE_VISITS';

export const STORE_SPECIALTIES = 'STORE_SPECIALTIES';
export const STORE_SPECIALTY = 'STORE_SPECIALTY';

export const STORE_DOCTORS = 'STORE_DOCTORS';
export const STORE_ENTITIES = 'STORE_ENTITIES';
export const STORE_ENTITIES_BRIEF = 'STORE_ENTITIES_BRIEF';

export const STORE_MODIFICATION_REQUESTS = 'STORE_MODIFICATION_REQUESTS';

export const STORE_REPORTS = 'STORE_REPORTS';

export const STORE_RATES = 'STORE_RATES';

export const STORE_WAREHOUSES = 'STORE_WAREHOUSES';

export const STORE_SALES = 'STORE_SALES';

export const STORE_STATISTICS = 'STORE_STATISTICS';

export const STORE_MEDICALREP_REQUESTS = 'STORE_MEDICALREP_REQUESTS';

export const STORE_MEDICALREP_TARGET = 'STORE_MEDICALREP_TARGET';

export const STORE_EXAMS = 'STORE_EXAMS';
export const STORE_EXAMS_RESULTS = 'STORE_EXAMS_RESULTS';

export const STORE_CLASSIFICATION = 'STORE_CLASSIFICATION';

export const RESET_VISITS = 'RESET_VISITS'

export const STORE_VISITS_PLAN = 'STORE_VISITS_PLAN'

export const STORE_PLANNED_VISITS_PLAN = 'STORE_PLANNED_VISITS_PLAN'

export const STORE_ROLES = 'STORE_ROLES'

export const PERMISSIONS = 'PERMISSIONS'

export const STORE_DIRECT_CHILDREN = 'STORE_DIRECT_CHILDREN'

export const STORE_USERS_FLOWERS = 'STORE_USERS_FLOWERS'

export const STORE_ROLE_TREE = 'STORE_ROLE_TREE'

export const STORE_USER_TREE = 'STORE_USER_TREE'

export const STORE_CITIES_IN_SECTOR = 'STORE_CITIES_IN_SECTOR'

export const STORE_ENTITIES_ALL = 'STORE_ENTITIES_ALL'

export const STORE_EMPLOYEES_ROLE = 'STORE_EMPLOYEES_ROLE'

export const STORE_ACCOUNTS = 'STORE_ACCOUNTS'

export const STORE_TEAMS = 'STORE_TEAMS'

export const CHILDREN_ROLES = 'CHILDREN_ROLES'

export const STORE_EMPLOYEES_COUNT = 'STORE_EMPLOYEES_COUNT'

export const STORE_DOUBLE_VISIT = 'STORE_DOUBLE_VISIT'

export const STORE_INFO_TYPE = 'STORE_INFO_TYPE'

export const STORE_SECTOR_MINE = 'STORE_SECTOR_MINE'

export const StoreVisitsAll = 'STORE_VISITS_All'

export const STORE_COMPANIES = 'STORE_COMPANIES'

export const STORE_MY_PLAN = 'STORE_MY_PLAN'

export const STORE_WORKS = 'STORE_WORKS'

export const STORE_MATERIALS_MEDICAL_REP = 'STORE_MATERIALS_MEDICAL_REP'

export const STORE_VISITS_ORDER = 'STORE_VISITS_ORDER'

export const STORE_UNDER_LIMIT_MATERIALS = 'STORE_UNDER_LIMIT_MATERIALS'

export const STORE_TRANSACTIONS_STATEMENT = 'STORE_TRANSACTIONS_STATEMENT'

export const STORE_MINE_MATERIALS = 'STORE_MINE_MATERIALS'