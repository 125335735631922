import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import moment from 'moment'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'
import BeeComponents from '@beetronix/bee-components'
import VueEllipseProgress from 'vue-ellipse-progress';
import { API_URL } from './constants';
import * as types from './store/mutation-types';
import '@beetronix/bee-components/lib/css/index.css';
import { permissions } from '@/helpers/enums'
import i18n from './i18n'
// import '@beetronix/bee-components/lib/css/index.css';

Vue.config.productionTip = false
Vue.use(BeeComponents);
Vue.use(VueEllipseProgress);

const eventBus = new Vue();
Vue.prototype.$eventBus = eventBus;

window.axios = axios.create({
	baseURL: API_URL
});

window.axios.interceptors.response.use(
	res => res,
	error => {
		if (error.response.status === 401 && router.currentRoute.name !== 'login') {
			store.dispatch('auth/logout');
			router.replace({ name: 'login' })
		}
		if (router.currentRoute.name !== 'login') {
			eventBus.$emit(
				'show-snackbar',
				error.response.data.message ||
				error.response.data.title ||
				'An error occurred ',
				'error'
			);
		}
		return Promise.reject(error)
	}
);

window.moment = moment;

if ('auth' in localStorage) {
	window.axios.defaults.headers.common['Access-Control-Allow-Origin'] = API_URL
	try {
		const auth = JSON.parse(localStorage.getItem('auth'));
		const authHeader = `Bearer ${auth.token}`;

		window.axios.defaults.headers.common.Authorization = authHeader;
		store.dispatch('auth/getSelf')
		.then(() => {
			store.commit(`auth/${types.LOGIN}`, auth);
		})
		.catch(() => {
			localStorage.removeItem('auth');
			router.replace({ name: 'login' });
		});
	} catch (e) {
		localStorage.removeItem('auth');
	}
} else {
	store.state.auth.youPermissions = {}
	router.replace({ name: 'login' });
}

Vue.use(VueMeta);
new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app')