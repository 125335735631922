<template>
	<div id="home">
		<!-- New administrative requests  -->
		<v-row class="ma-0">
			<v-col lg="12" cols="12"  class="text-h6">
				{{ $t('Home.new-administrative-requests') }}
			</v-col>
			<v-col lg="2" sm="4">
				<v-btn
					class="text-capitalize text-center"
					color="dark-yellow px-6 white--text"
					width="100%"
					height="80px"
					:loading="loading"
					:to="{ name:'day-off-requests' }"
				>
					<div class="d-flex flex-column">
						<span class="btn-num">
							{{ leaveRequests }}
						</span>

						<span>
							{{ $t('Home.leave-requests') }}
						</span>
					</div>
				</v-btn>
			</v-col>
			<v-col lg="2" sm="6">
				<v-btn
					class="text-capitalize text-center"
					color="dark-yellow white--text"
					width="100%"
					height="80px"
					:loading="loading"
					:to="{ name:'day-off-requests' }"
				>	
					<div class="d-flex flex-column">
						<span class="btn-num">{{ administrativeRequests }}</span>
						<span>{{ $t('Home.administrative-day') }}</span>
					</div>
				</v-btn>
			</v-col>
		</v-row>

		<!-- Modification requests -->
		<v-row class="ma-0">
			<v-col lg="12" cols="12" class="text-h6">
				{{ $t('Home.modification-requests') }}
			</v-col>
			<v-col lg="2">
				<v-btn
					class="text-capitalize text-center"
					color="dark-yellow white--text"
					width="100%"
					height="80px"
					:loading="loading"
					:to="{ name:'modification-requests' }"
				>
					<div class="d-flex flex-column">
						<span class="btn-num">{{ entityModifications }}</span>
						<span>{{ $t('Home.modification') }}</span>
					</div>
				</v-btn>
			</v-col>
			<v-col lg="2">
				<v-btn
					class="text-capitalize text-center"
					color="dark-yellow white--text"
					width="100%"
					height="80px"
					:loading="loading"
					:to="{ name:'modification-requests' }"
				>
					<div class="d-flex flex-column">
						<span class="btn-num">{{ entityCreations }}</span>
						<span>{{ $t('Home.addition') }}</span>
					</div>
				</v-btn>
			</v-col>
			<v-col lg="2">
				<v-btn
					class="text-capitalize text-center"
					color="red lighten-1 white--text"
					width="100%"
					height="80px"
					:loading="loading"
					:to="{ name:'modification-requests' }"
				>
					<div class="d-flex flex-column">
						<span class="btn-num">{{ entityDeletions }}</span>
						<span>{{$t('Home.deletion')}}</span>
					</div>
				</v-btn>
			</v-col>
		</v-row>

		<!-- Office visits yesterday -->
		<v-row class="ma-0">
			<v-col lg="12" cols="12" class="text-h6">
				{{ $t('Home.office-visits-yesterday') }}
				<span class="grey--text text--lighten-1">
					{{ moment().subtract(1, 'days').format('YYYY-MM-DD') }}
				</span>
			</v-col>
			<v-col lg="2" sm="4" >
				<!-- <v-btn
					class="text-capitalize btn-num text-h6"
					:loading="loading"
				> -->
				<span class="fs-m">
					{{visitsNumber}} / {{totalVisitsNumber}}
				</span>
				<!-- </v-btn> -->
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
	export default {
		name: 'Home',

		data(){
			return {
				visitsNumber: null,
				totalVisitsNumber: null,
				leaveRequests: 0,
				administrativeRequests: 0,
				entityModifications: 0,
				entityCreations: 0,
				entityDeletions: 0,

				loading: false,
			}
		},
		
		computed:{
			...mapState({
				medicalreps: state => state.medicalreps.medicalreps,
				employeeId: state => state.auth.userData.id
			})
		},

		methods:{
			moment
		},

		mounted(){
			this.loading = true;
			Promise.all([
				window.axios.get('/home').then(response => {
					this.leaveRequests = response.data.leaveRequests;
					this.administrativeRequests = response.data.administrativeRequests;
					this.entityModifications = response.data.entityModifications;
					this.entityCreations = response.data.entityCreations;
					this.entityDeletions = response.data.entityDeletions;
					this.visitsNumber = response.data.visitsYesterday;
					this.totalVisitsNumber = response.data.visitTargets;
				})
			])
			.finally(() => {
				this.loading = false;
			})
		},

		metaInfo: {
			title: 'Octopharma'
		},
	}
</script>
<style scoped>

.btn-num {
	font-size: 30px;
	font-weight: bolder;
}
.fs-m {
	font-size: 23px;
	font-weight: bolder;
}
</style>
