import * as types from '../mutation-types'

export default {
    namespaced: true,
    state:{
        visits: [],
        visitsOrder: []
    },

    actions:{
        fetchAll({ commit }){
            return window.axios.get('/visits').then((response) => {
                commit(types.STORE_VISITS, response.data);

                return response.data;
            })
        },

        fetchByDay({ commit }, { day }){
            return window.axios.get('/visits', {
                params:{
                    day
                }
            })
            .then((response) => {
                return response.data;
            })
        },

        delete({ commit }, { id }) {
            return window.axios.delete(`/visits/${id}`)
        },

        fetchByFilter({ commit }, { fromDate, toDate, entityType, regionIds, employeeId, entityName, specialtyId, workload, page, PerPage, OrderByWorkLoad, OrderByName, IsMedicalRep }){
            var urlSearchParams = new URLSearchParams();
            var params = { fromDate, toDate, entityType, employeeId, entityName, specialtyId, workload, page, PerPage, OrderByWorkLoad, OrderByName, IsMedicalRep };

            Object.keys(params).forEach(param => {
                if (params[param] !== null && params[param] !== undefined) {
                    urlSearchParams.append(param, params[param]);
                } 
            })
            regionIds.forEach(id => {
                urlSearchParams.append("regionIds", id);
            });
            
            return window.axios.get('/visits', {
                params: urlSearchParams
            })
            .then((response) => {
                return {
                    data: response.data,
                    total: parseInt(response.headers['x-pagination-total-count'])
                };
            })
        },

        fetchById({ commit }, { id }){
            return window.axios.get(`/visits/${id}`).then((response) => {
                return response.data;
            })
        },

        fetchVisitsWithoutPagination({ commit }, { fromDate, toDate, employeeId, regionIds, specialtyId }) {
            const urlSearchParams = new URLSearchParams();

            const params = { fromDate, toDate, employeeId, specialtyId };
            Object.keys(params).forEach(param => {
                if (params[param]) urlSearchParams.append(param, params[param]);
            })
            if (Array.isArray(regionIds)) {
                regionIds.forEach(id => urlSearchParams.append('regionIds', id))
            }

            return window.axios.get('/visits/BetweenTwoDates', { params: urlSearchParams }).then(({ data }) => {
                return data
            })
        },

        fetchVisitsOrder({ commit }, { FromDate, ToDate, RegionIds, EmployeeId, Page, PerPage }) {
            const urlSearchParams = new URLSearchParams();
            var params = { FromDate, ToDate, EmployeeId, Page, PerPage };
            Object.keys(params).forEach(param => {
                if (params[param]) urlSearchParams.append(param, params[param]);
            })
            if (Array.isArray(RegionIds)) {
                RegionIds.forEach(id => urlSearchParams.append('regionIds', id))
            }
			return window.axios.get('/Visits/OrderdVisits', { 
                params: urlSearchParams
            }).then((response) => {
				commit(types.STORE_VISITS_ORDER, response.data)
				return response.data
			})
		},

        updateVisit({ commit }, { id, entityId, otherSamples, notes, objections, nextVisitNotes, purpose, materialAmounts, unavailableMaterialIds, competitiveSamples, createdAt, coreMessage, durationInMinutes }) 
        {
            return window.axios.put(`/visits/${id}`, { id, entityId, otherSamples, notes, objections, nextVisitNotes, purpose, materialAmounts, unavailableMaterialIds, competitiveSamples, createdAt, coreMessage, durationInMinutes }).then(({ data }) => {
                return data
            })
        }
    },

    mutations:{
        [types.STORE_VISITS](state, visits){
            state.visits = visits;
        },
        [types.STORE_VISITS_ORDER] (state, visitsOrder) {
			state.visitsOrder = visitsOrder
		}
    },

    getters:{
        getVisitById: state => id => state.visits.find(c => c.id === id),
        getVisitOrderByMedicalRep: state => employeeId => state.visitsOrder.find(c => c.employeeId === employeeId)

    }
}