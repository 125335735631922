<template>
    <div>
        <!-- add dialog -->
        <v-dialog v-model="addEventDialog" width="550px">
            <v-card>
                <div class="d-flex justify-end background-title-card mb-3">
                    <v-card-title
                        class="d-flex justify-center w-100 text-uppercase text-body-2 justify-center py-0 white--text"
                    >
                        Add new event
                    </v-card-title>
                    <!-- close icon button -->
                    <v-btn icon @click="addEventDialog = false">
                        <v-icon color="white" >
                            mdi-close-circle-outline
                        </v-icon>
                    </v-btn>
                </div>

                <v-card-text class="pb-0">
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="dateEvent"
                            	label="Visit date" 
                                hide-details="auto"
                                :disabled="submitLoading" 
                                dense
                                outlined
                                readonly
                            />
                        </v-col>

                        <v-col align-self="center">
                            <v-autocomplete
                               v-model="doctorName"
                                :items="entities"
                            	label="Doctor name"
                                item-text="name"
                                item-value="id"
                            	outlined
                            	dense
                            	hide-details="auto"
                                :disabled="submitLoading"
                            />
                        </v-col>

                        <v-col align-self="center">
                            <v-autocomplete
                                :items="medicalReps"
                                item-text="name"
                                item-value="id"
                            	label="Medical rep"
                            	outlined
                            	dense
                            	hide-details="auto"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text v-if="0">
                    <bee-handy-smart-table
                        v-model="tableInputs"
                        ref="table"
                        :headers="header"
                        :items="entries"
                        zebra
                        hide-default-footer
                        show-add-row
                        @keypress.enter="addItem()"
                    >
                        <template v-slot:input.date="{on, attr}">
                            <bee-date-input 
                                input-format-order="DD-MM-YYYY"
                                dense
                                v-on="on"
                                v-bind="attr"
                            ></bee-date-input>
                        </template>

                        <template v-slot:item.actions="{}">
                            <v-btn color="edit-color" icon>
                                <v-icon> mdi-pencil </v-icon>
                            </v-btn>

                            <v-btn color="alert-color" icon>
                                <v-icon> mdi-delete </v-icon>
                            </v-btn>
                        </template>
                    </bee-handy-smart-table>
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                    	@click="addVisit()"
                    	text
                    	color="success"
                    	:loading="submitLoading"
                    >
                        Save
                    </v-btn>

                    <v-btn
                    	text
                    	color="alert-color"
                    	@click="addEventDialog = false"
                    	:loading="submitLoading"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <h2 class="text-h6 primary--text text-capitalize mb-2">Medical rep plan</h2>
        <!-- filter -->
        <v-row>
            <v-col cols="2">
                <v-autocomplete 
                    :items="medicalReps"
                    item-text="name"
                    item-value="id"
                    label="Medical rep"
                    outlined
                    dense
                    hide-details="auto"
                />
            </v-col>    
            
            <v-col cols="2">
                <v-autocomplete
                    :items="entities"
                    item-text="name"
                    item-value="id"
                    label="Doctor name"
                    outlined
                    dense
                    hide-details="auto"
                 />
            </v-col>

            <v-col cols="3">
                <bee-date-input 
                    input-format-order="DD-MM-YYYY"
                    dense
                ></bee-date-input>
            </v-col>

            <v-spacer />

            <v-col align-self="center" class="d-flex justify-end">
                <v-btn color="success" small class="mx-2">
                    <v-icon> mdi-magnify </v-icon>
                    Search
                </v-btn>

                <v-btn color="alert-color white--text" small>
                    Cancel
                </v-btn>
            </v-col>
        </v-row>
        <v-sheet height="1500">
            <v-calendar
                ref="calendar"
                v-model="value"
                :weekdays="weekday"
                :type="type"
                :events="events"
                :event-overlap-threshold="30"
                :category-days="2"
                :event-more="false"
                interval-height=""
                :height-event="10"
                @click:event="showEvent"
      
            >
                <template v-slot:event="{ event, timed, eventSummary }">
                    <div class="v-event-draggable">
                        <!-- <component :is="{ render: eventSummary }"></component> -->
                        <p class="text-center" v-if="eventSummary"> {{event.start}} </p>
                    </div>
                    <div
                        v-if="timed"
                        class="v-event-drag-bottom"
                        @mousedown.stop="extendBottom(event)"
                    ></div>
                </template>

				<template v-slot:day="{ date }">
					<div style="width: 100%" class="d-flex justify-center align-items-center">
						<v-btn
							icon
							color="success"
							class="d-flex justify-center"
							@click="dateEvent = date;  addEventDialog = true"
						>
							<v-icon> mdi-plus-circle </v-icon>
						</v-btn>
					</div>
				</template>
            </v-calendar>
        </v-sheet>

        <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
        >
            <v-card
                color="grey lighten-4"
                min-width="350px"
                flat
            >
                <v-toolbar
                    :color="selectedEvent.color"
                    dark
                >
                    <v-btn icon>
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-toolbar-title v-html="selectedEvent.start"></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon>
                        <v-icon>mdi-heart</v-icon>
                    </v-btn>
                    <v-btn icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <span v-html="selectedEvent.details"></span>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="selectedOpen = false"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
  export default {
    data: () => ({
        yy: null,
        addEventDialog: false,
        tableInputs: {},
        type: 'month',
        types: ['month', 'week', 'day', '4day'],
        mode: 'stack',
        modes: ['stack', 'column'],
        weekday: [0, 1, 2, 3, 4, 5, 6],
        weekdays: [
            { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
            { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
            { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
            { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
        ],
        value: '',
        events: [],
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],

        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,

        entries: [],
        dateEvent: null,
        doctorName: null,
        submitLoading: false
        // events.find(c => c.date === selectedEvent.start)
    }),
    computed: {
        ...mapState({
            plannedVisits: state => state.visitsPlan.plannedVisits.plannedVisits,
            entities: state => state.entities.entities,
            medicalReps: state => state.medicalreps.medicalreps,
        }),
        ...mapGetters({
            // getEntityById: 'entities/getEntityById'
        }),
        header () {
            return [
                {
                    text: 'Date',
                    name: 'date',
                    value: 'date',
                    type: 'date'
                },
                {
                    text: 'Doctor',
                    name: 'name',
                    value: 'name'
                },
                {
                    text: '',
                    name: 'actions',
                    value: 'actions'
                }
            ]
        }
    },
    methods: {
        saveDateEvent (date) {
            // this.yy = this.$refs.calendar; 
            this.dateEvent = date.date
            console.log(this.dateEvent)
        },
        showEvent ({ nativeEvent, event }) {
            // const open = () => {
            //     this.selectedEvent = event
            //     this.selectedElement = nativeEvent.target
            //     requestAnimationFrame(() => requestAnimationFrame(() => { this.selectedOpen = true }))
            // }

            // if (this.selectedOpen) {
            //     this.selectedOpen = false
            //     requestAnimationFrame(() => requestAnimationFrame(() => open()))
            // } else {
            //     open()
            // }
            // nativeEvent.stopPropagation()
			this.addEventDialog = true
			this.dateEvent = event.start
            this.doctorName = event.entityId
        },

        extendBottom (event) {
            this.createEvent = event
            this.createStart = event.start
            this.extendOriginal = event.end
        },

    toTime (tms) {
        // return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).
        return tms.date
    },

      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },

        addItem () {
            this.entries.push({
                ...this.tableInputs
            })
            this.$nextTick(() => {
                this.$refs.table.resetInputs()
                this.$refs.table.resetInputsFocus()
            })
        },

        addItemToEvent () {
            this.events.concat(this.entries)
            this.addEventDialog = false
        },

        addVisit () {
            this.submitLoading = true
            this.$store.dispatch('visitsPlan/create', { entityId: this.doctorName, date: this.dateEvent }).then(() => {
                this.submitLoading = false
            })
        }
    },

    created () {
        this.loading = true
        this.$store.dispatch('entities/fetchByFilter', { type: 1, nonDeleted: true, regionIds: [] }).then((data) => {
            console.log(this.entities)
        })
        this.$store.dispatch('medicalreps/fetchAll', true)
        this.$store.dispatch('visitsPlan/fetchAll').then((data) => {
            this.events = data.data.map(c => ({ ...c, start: c.date.split('T')[0], end: c.date.split('T')[0] }))
            console.log(this.events)
        }).finally(() => {
            this.loading = false
        })
    }
  }
</script>
