<template>
    <div>
		<v-dialog
			v-model="dialog"
			persistent
			width="600"
			:createAt="create"
		>
            <v-card flat>
                <!-- title -->
                <v-toolbar dark flat color="primary" class="text-uppercase">
                    <v-spacer/>
                    {{title}}
                    <v-spacer/>
                    <v-toolbar-items text samll>
                        <v-btn small text @click="dialog=false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                
                <v-form @submit.prevent="submit" ref="form">
                    <!-- radio buttons -->
                    <v-radio-group
						row
						hide-details
						v-model="dirType"
						v-if="id === null"
						@change="reset"
                    >
                        <v-radio :label="$t('inputs.doctor')" :value="0" class="mx-auto" color="success"/>
                        <v-radio :label="$t('inputs.pharmacy')" :value="1" class="mx-auto" color="success"/>
                        <v-radio :label="$t('inputs.center')" :value="2" class="mx-auto" color="success"/>
                    </v-radio-group>

                    <!-- card content -->
                    <v-card-text class="pb-0">
                        <v-container>
                            <v-row dense>
								<v-col md="6" cols="12" align-self="end">
									<v-autocomplete
										v-model="cityId"
										:items="cities"
										item-text="name"
										item-value="id"
										:label="$t('inputs.city')"
										dense
										hide-details
										persistent-hint
										@change="getRegions()"
									></v-autocomplete>
								</v-col>
                                <!-- region -->
                                <v-col md="6" cols="12">
									<v-autocomplete
										v-model="regionId"
										:items="regions"
										item-text="name"
										item-value="id"
										:label="$t('inputs.region')"
										@change="fetchEntities"
										:rules="rules.required"
										hide-details="auto"
										:loading="regionsLoading"
										:disabled="regionsLoading || !cityId"
									></v-autocomplete>
                                </v-col>

                                <!-- entity -->
                                <v-col md="6" cols="12">
									<v-autocomplete
										v-model="entityId"
										:items="entities"
										item-text="name"
										item-value="id"
										:label="$t('inputs.customer-name')"
										:loading="EntitiesLoading"
										:disabled="EntitiesLoading || regionId === null"
									></v-autocomplete>
                                </v-col>

                                <!-- samples -->
                                <v-col cols="12">
                                    <div class="d-flex flex-grow-1 justify-space-between align-center">
                                        <div class="d-flex align-center">
                                            <v-icon class="me-2">mdi-pill</v-icon>
                                            <span class="text-body-1"> {{$t('AdministrativeVisit.materials')}} </span>
                                        </div>

                                        <v-btn text @click="addSample">
                                            <v-icon color="grey darken-3">mdi-plus-circle-outline</v-icon>
                                        </v-btn>
                                    </div>
                                    
                                    <!-- sample card -->
                                    <v-card v-if="samples.length > 0">
                                        <v-card-text>
                                            <v-row dense v-for="sample in samples" :key="sample.id" class="align-center">
                                                <!-- sample -->
                                                <v-col cols="7">
													<v-autocomplete
														v-model="sample.data.materialId"
														:items="materials"
														:item-text="getFullMaterialName"
														item-value="id"
														:label="$t('inputs.sample')"
														dense
														:rules="rules.required"
														hide-details
													></v-autocomplete>
                                                </v-col>

                                                <!-- amount -->
                                                <v-col :cols="samples.length > 1 ? 3 : 5">
													<v-text-field
														v-model.number="sample.data.amount"
														:label="$t('inputs.amount')"
														type="number"
														min="0"
														dense
														hide-spin-buttons
														hide-details
														:rules="rules.number"
													></v-text-field>
                                                </v-col>

												<v-col>
													<v-text-field 
														v-model.number="sample.data.sellPrice"
														:label="$t('inputs.price')"
														type="number" 
														hide-details
														hide-spin-buttons
														dense
													/>
												</v-col>

                                                <!-- remove button -->
                                                <v-col v-if="samples.length > 1" cols="2" class="pa-0">
													<v-btn text small @click="removeSample(sample.id)">
                                                        <v-icon color="grey darken-3">mdi-minus-circle-outline</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <!-- unavailable materialIds -->
                                <v-col md="6" cols="12" v-if="dirType === 1">
									<v-autocomplete
										v-model="unavailableMaterialIds"
										:items="allMaterials"
										:item-text="getFullMaterialName"
										item-value="id"
										:label="$t('AdministrativeVisit.unavailable-products')"
										multiple
										:rules="rules.required"
										hide-details="auto"
										prepend-icon="mdi-close"
										required
									>
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0" class="me-1">{{ getFullMaterialName(item) }}</span>
                                            <span
                                                v-if="index === 1"
                                                class="grey--text text-caption"
                                            >
                                                (+{{ unavailableMaterialIds.length - 1 }} {{ $t('others') }})
                                            </span>
                                        </template>
                                    </v-autocomplete>
                                </v-col>

                                <!-- competitors -->
                                <v-col md="6" cols="12" v-if="dirType === 1">
                                    <div class="d-flex flex-grow-1 justify-space-between align-center">
                                        <div class="d-flex align-center">
                                            <v-icon class="me-2">mdi-trending-up</v-icon>
                                            <span class="text-body-1">{{ $t('AdministrativeVisit.competitors') }}</span>
                                        </div>

                                        <v-btn text @click="addCompetitor">
                                            <v-icon color="grey darken-3">mdi-plus-circle-outline</v-icon>
                                        </v-btn>
                                    </div>

                                    <!-- Competitor card -->
                                    <v-card v-if="competitors.length > 0" class="mt-4">
                                        <v-card-text>
                                            <v-row
                                            v-for="competitor in competitors"
                                            :key="competitor.id"
                                            class="align-center"
                                            >
                                                <!-- competitor -->
                                                <v-col cols="10">
													<v-text-field
														v-model="competitor.value"
														label="Competitor"
														dense
														hide-details
														required
													></v-text-field>
                                                </v-col>

                                                <!-- remove button -->
                                                <v-col cols="2" class="pa-0">
                                                    <v-btn
                                                    text
                                                    small
                                                    @click="removeCompetitor(competitor.id)"
                                                    >
                                                        <v-icon color="grey darken-3">mdi-minus-circle-outline</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

								<!-- coreMessage -->
                                <v-col md="6" cols="12">
									<v-text-field
										v-model="coreMessage"
										:label="$t('inputs.core-message')"
										prepend-icon="mdi-note-outline"
										hide-details
									></v-text-field>
                                </v-col>

								<!-- durationInMinutes -->
                                <v-col md="6" cols="12">
									<v-text-field
										v-model.number="durationInMinutes"
										:label="$t('inputs.duration-in-minutes')"
										type="number"
										hide-spin-buttons
										:suffix="$t('AdministrativeVisit.minutes')"
										min="0"
										prepend-icon="mdi-clock-outline"
										hide-details
									></v-text-field>
                                </v-col>

                                <!-- other samples -->
                                <v-col md="6" cols="12">
									<v-text-field
										v-model="otherSamples"
										:label="$t('inputs.other-materials')"
										prepend-icon="mdi-plus-minus-box"
										hide-details
									></v-text-field>
                                </v-col>

                                <!-- visitNotes -->
                                <v-col md="6" cols="12">
									<v-text-field
										v-model="visitNotes"
										:label="$t('AdministrativeVisit.visit-notes')"
										prepend-icon="mdi-note-multiple-outline"
										hide-details
									></v-text-field>
                                </v-col>

                                <!-- objection -->
                                <v-col md="6" cols="12">
									<v-text-field
										v-model="objection"
										:label="$t('AdministrativeVisit.objection')"
										prepend-icon="mdi-alert-circle-outline"
										hide-details
									></v-text-field>
                                </v-col>

                                <!-- nextCall -->
                                <v-col md="6" cols="12">
									<v-text-field
										v-model="nextCall"
										:label="$t('AdministrativeVisit.next-call')"
										prepend-icon="mdi-check"
										hide-details
									></v-text-field>
                                </v-col>

                                <!-- center notes -->
                                <v-col md="6" cols="12" v-if="dirType === 2">
									<v-text-field
										v-model="centerNotes"
										:label="$t('inputs.notes')"
										prepend-icon="mdi-note-multiple-outline"
										hide-details
									></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" text type="submit" :loading="loading" :disabled="loading">
                            {{ $t('save') }}
                        </v-btn>
                        <v-btn color="grey darken-1" text @click="dialog = false">
                            {{ $t('close') }}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import rules from '../validation rules'
import { mapState, mapGetters } from 'vuex'
export default {
	props: {
		create: {
			type: String
		}
	},
	data() {
		return {
			// helpers
			id: null,
			dialog: false,

			title: null,

			loading: false,
			EntitiesLoading: false,
			regionsLoading: false,
			rules,
            
			sampleCounter: 1,
			competitorCounter: 0,

			// data
			dirType: 0,
			regionId: null,
			cityId: null,
			entityId: null,
			samples: [{
				id: 1,
				data: {
					materialId: null,
					amount: null,
					sellPrice: null
				}
			}],
			coreMessage: null,
			durationInMinutes: null,
			otherSamples: null,
			visitNotes: null,
			objection: null,
			nextCall: null,
			unavailableMaterialIds: [],
			competitors: [],
			centerNotes: null
		}
	},

	methods: {
		reset() {
			if (this.$refs.form) this.$refs.form.resetValidation();

			this.id = null;
			this.sampleCounter = 1;
			this.competitorCounter = null;

			this.cityId = null;
			this.regionId = null;
			this.entityId = null;
			this.samples = [{
				id: 1,
				data: {
					materialId: null,
					amount: null,
					sellPrice: null
				}
			}];
			this.coreMessage = null;
			this.durationInMinutes = null;
			this.otherSamples = null;
			this.visitNotes = null;
			this.objection = null;
			this.nextCall = null;
			this.unavailableMaterialIds = [];
			this.competitors = [];
			this.centerNotes = null;
		},

		// samples
		addSample() {
			this.samples.push({
				id: ++this.sampleCounter,
				data: {
					materialId: null,
					amount: null,
					sellPrice: null
				}
			})
		},

		removeSample(id) {
			const index = this.samples.findIndex(c => c.id === id);
			this.samples.splice(index, 1);
			this.samples.forEach(sample => {
				if (sample.id > index) {
					sample.id--;
				}
			})
			this.sampleCounter--;
		},
        
		// competitor
		addCompetitor() {
			this.competitors.push({
				id: this.competitorCounter++,
				value: null
			})
		},

		removeCompetitor(index) {
			this.competitors.splice(index, 1);
			this.competitors.forEach(competitor => {
				if (competitor.id > index) {
					competitor.id--;
				}
			})
			this.competitorCounter--;
		},

		// find data
		getFullMaterialName(material) {
			const details = [
				this.getMaterialFormById(material.materialFormId).name,
				material.dose,
				material.strength
			];

			return `${material.name} (${details.filter(c => c != null).join(' ')})`;
		},

		fetchEntities() {
			this.EntitiesLoading = true;
			let type;
			switch (this.dirType) {
			case 0 : type = 1; break; // Doctor
			case 1 : type = 2; break; // Pharmacy
			case 2 : type = 0; break; // Center
			}

			this.$store.dispatch('entities/fetchByFilter', { type, regionIds: [this.regionId] })
				.finally(() => {
					this.EntitiesLoading = false;
				})
		},

		// dialog functions
		addVisit() {
			this.reset();
			this.dirType = 0;
			this.title = this.$t('AdministrativeVisit.add-visit');
			this.dialog = true;
		},

		editVisit(id) {
			this.reset();
			this.id = id;
			this.fillData();
			this.title = this.$t('AdministrativeVisit.edit-visit');
			this.dialog = true;
		},

		fillData() {
			const visit = this.getVisitById(this.id);
			// console.log(visit)
			switch (visit.entity.entityType) {
			case 0: this.dirType = 2; break;
			case 1: this.dirType = 0; break;
			case 2: this.dirType = 1; break;
			}
			
			this.regionId = visit?.entityAddresses[0]?.regionId;
			this.cityId = this.getRegionAllById(this.regionId)?.cityId;
			this.getRegions();

			this.entityId = visit.entity.id;
			this.coreMessage = visit.coreMessage;
			this.durationInMinutes = visit.durationInMinutes;
			this.otherSamples = visit.otherSamples;
			this.visitNotes = visit.notes;
			this.objection = visit.objections;
			this.nextCall = visit.purpose;
			this.centerNotes = visit.nextVisitNotes;
            
			visit.unavailableMaterials.forEach(materialName => {
				this.unavailableMaterialIds.push(this.getMaterialByName(materialName).id);
			})
			this.samples = [];
			visit.materialAmounts.forEach(materialAmount => {
				this.samples.push({
					id: ++this.sampleCounter,
					data: {
						materialId: materialAmount.materialId,
						amount: materialAmount.amount,
						sellPrice: materialAmount.sellPrice
					}
				})
			});

			visit.competitiveSamples.forEach(sample => {
				this.competitors.push({
					id: this.competitorCounter++,
					value: null
				})
			});

			this.fetchEntities();
		},

		submit() {
			if (this.$refs.form.validate()) {
				this.loading = true;
				console.log(this.create)
				// create visit
				if (!this.id) {
					this.$store.dispatch('mineVisits/create', {
						entityId: this.entityId,
						coreMessage: this.coreMessage,
						durationInMinutes: this.durationInMinutes,
						otherSamples: this.otherSamples,
						notes: this.visitNotes,
						objections: this.objection,
						nextVisitNotes: this.centerNotes,
						purpose: this.nextCall,
						materialAmounts: this.samples.map(c => c.data),
						// materialAmounts:[{materialId: "4957090b-3b58-4569-efd3-08dc06082d85", amount: 6}]
						unavailableMaterialIds: this.unavailableMaterialIds,
						competitiveSamples: this.competitors.map(c => c.value),
						createdAt: this.create
					})
						.then(() => {
							this.$eventBus.$emit('show-snackbar', 'Visit is created');
							this.$emit('on-save');
							this.dialog = false;
						})
						.finally(() => {
							this.loading = false;
						})
				} else {
					// edit visit
					this.$store.dispatch('mineVisits/update', {
						id: this.id,
						entityId: this.entityId,
						coreMessage: this.coreMessage,
						durationInMinutes: this.durationInMinutes,
						otherSamples: this.otherSamples,
						notes: this.visitNotes,
						objections: this.objection,
						nextVisitNotes: this.centerNotes,
						purpose: this.nextCall,
						materialAmounts: this.samples.map(c => c.data),
						unavailableMaterialIds: this.unavailableMaterialIds,
						competitiveSamples: this.competitors.map(c => c.value)
					})
						.then(() => {
							this.$eventBus.$emit('show-snackbar', 'Visit is edited');
							this.$emit('on-save');
							this.dialog = false;
						})
						.finally(() => {
							this.loading = false;
						})
				}
			}
		},
		
        getRegions() {
            this.regionsLoading = true
            this.$store.dispatch('regions/fetchAll', { cityId: this.cityId }).finally(() => {
                this.regionsLoading = false
            })
        }
	},

	computed: {
		...mapState({
			regions: state => state.regions.regions,
			allMaterials: state => state.materials.materials,
			transactions: state => state.materialTransactions.transactions,
			materialForms: state => state.materialForms.materialForms,
			entities: state => state.entities.entities,
			cities: state => state.cities.cities
		}),

		...mapGetters({
			getRegionAllById: 'regions/getRegionAllById',
			getVisitById: 'mineVisits/getVisitById',
			getMaterialByName: 'materials/getMaterialByName',
			getMaterialById: 'materials/getMaterialById',
			getMaterialFormById: 'materialForms/getMaterialFormById'
		}),

		materials() {
			const availableMaterials = [];
			const availableMaterialsIds = this.transactions.map(c => c.materialId);
			availableMaterialsIds.forEach(id => availableMaterials.push(this.getMaterialById(id)))
			return availableMaterials;
		}
	}
}
</script>

<style>

</style>