<template>
    <div>
        <v-row>
            <v-col class="pb-0">
                <h1 class="text-h6 primary--text text-capitalize">
                    {{$t('routes.loaned-items')}}
                </h1>
            </v-col>
        </v-row>
       
        <!-- filter -->
        <v-row class="mt-1">
            <v-col cols="4" sm="4"  md="3">
                <v-autocomplete
                    v-model="medicalRepIds"
                    :items="medicalreps"
                    item-text="name"
                    item-value="id"
                    :label="$t('inputs.medical-rep')"
                    outlined
                    dense
                    hide-details
                    multiple
                    :loading="loading"
                    :disabled="loading"
                >
                    <template v-slot:selection="{ item, index }">
                        <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                        <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                        >
                            (+{{ medicalRepIds.length - 1 }})
                        </span>
                    </template>   
                </v-autocomplete>
            </v-col>

            <!-- type -->
            <v-col cols="4" sm="4"  md="2">
                <v-autocomplete
                    v-model="type"
                    :items="entityTypes"
                    item-text="name"
                    item-value="id"
                    :label="$t('inputs.type')"
                    outlined
                    dense
                    hide-details
                    :loading="loading"
                    :disabled="loading || loadingEntity"
                    @change="getEntity()"
                />
            </v-col>

            <!-- doctor -->
            <v-col cols="4" sm="4"  md="2">
                <v-autocomplete
                    v-model="entityId"
                    :items="entitiesBrief"
                    item-text="name"
                    item-value="id"
                    :label="$t('inputs.doctor')"
                    outlined
                    dense
                    hide-details
                    :loading="loading || loadingEntity"
                    :disabled="loading || !type"
                />
            </v-col>

            <!-- items -->
            <v-col cols="4" sm="4"  md="2">
                <v-autocomplete
                    v-model="materialId"
                    :items="materials"
                    item-text="name"
                    item-value="id"
                    :label="$t('inputs.item')"
                    outlined
                    dense
                    hide-details
                    :loading="loading"
                    :disabled="loading"
                />
            </v-col>

            <!--  -->
            <v-col cols="auto" class="pt-0" align-self="center">
                <v-radio-group
                    v-model="dateType"
                    row
                    class="mt-0"
                    hide-details
                    :disabled="loading"
                >
                <v-radio
                    :label="$t('daily-date')"
                    value="1"
                ></v-radio>
                <v-radio
                    :label="$t('from-date-to-date')"
                    value="2"
                ></v-radio>
                </v-radio-group>
            </v-col>

            <v-col v-if="dateType === '2'" class="py-0" align-self="center">
                <bee-date-picker
                    v-model="startDate"
                    input-label="Start Date"
                    menu-picker
                    dense
                    hide-details
                    :input-props="{
                        outlined: true,
                        dense: true,
                        disable: loading
                    }"
                ></bee-date-picker>
            </v-col>

            <!-- end date -->
            <v-col v-if="dateType === '2'" cols="2" class="pt-0 " align-self="center">
                <bee-date-picker
                    v-model="endDate"
                    input-label="End Date"
                    menu-picker
                    dense
                    hide-details
                    :input-props="{
                        outlined: true,
                        dense: true,
                        disable: loading
                    }"
                ></bee-date-picker>
            </v-col>

            <!-- filter -->
            <v-col v-if="dateType === '1'" cols="6" sm="5"  md="3" class="pa-1 pt-0" align-self="center">
                <bee-date-input
                    v-model="date"
            	    :append-icon="$i18n.locale === 'en' ? 'mdi-menu-right' : 'mdi-menu-left'"
					:prepend-icon="$i18n.locale === 'en' ? 'mdi-menu-left' : 'mdi-menu-right'"
                    :label="$t('inputs.date')"
                    input-format-order="DD-MM-YYYY"
                    dense
                    :loading="loading"
                    :disabled="loading"
                    hide-details
                    @click:append="addDate()"
                    @click:prepend="subDate()"
                ></bee-date-input>
            </v-col>

            <v-col cols="auto" class="mb-2 pt-0" align-self="center">
                <v-btn
                	color="success"
                	small
                	:loading="loading"
                	:disabled="loading"
                    @click="getLoanedMaterials()"
                >
                    {{ $t('search') }}
                </v-btn>
                <v-btn
                	color="alert-color white--text"
                	class="mx-1"
                	small
                	:disabled="loading"
                    @click="reset()"
                >
                    {{ $t('reset') }}
                </v-btn>
            </v-col>
        </v-row>    
        <bee-handy-table
            :headers="headers"
            :items="items"
            zebra
            dense
            :height="$vuetify.breakpoint.height - 120"
            :shown-rows-text="$t('shown-rows')"
            pagination-on-scroll
            @click:row="goToStatement"
        ></bee-handy-table>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import { entityType } from '@/helpers/enums'

export default {
    data() {
        return {
            medicalRepIds: [],
            entityId: null,
            materialId: null,
            dateType: '1',
            date: moment().format('YYYY-MM-DD'),
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().endOf('month').format('YYYY-MM-DD'),  
            items: [],
            entityType,
            loading: false,
            entityTypes: [
                { id: entityType.center, name: this.$t('tabs.centers') },
                { id: entityType.doctor, name:  this.$t('tabs.doctor') },
                { id: entityType.pharmacy, name: this.$t('tabs.pharmacies') },
                // { id: entityType.external, name: 'externals' }
            ],
            type: null,
            loadingEntity: false
        }
    },

    computed: {    
        ...mapState({
            materials: state => state.materials.materials,
            medicalreps: state => state.medicalreps.medicalreps,
            entitiesBrief: state => state.entities.entitiesBrief
        }),

        ...mapGetters({
            getMaterialById: 'materials/getMaterialById',
        }),
        headers () {
            return [
                {
                    text: this.$t('headers.item'),
                    name: 'materialId',
                    value: 'materialId',
                    setValueExpr: val => this.getMaterialById(val)?.name
                },
                {
                    text: this.$t('headers.entity-name'),
                    name: 'entityName',
                    value: 'entityName'
                },
                {
                    text: this.$t('headers.medical-rep-name'),
                    name: 'fromAccountName',
                    value: 'fromAccountName'
                },
                {
                    text: this.$t('headers.date'),
                    name: 'date',
                    value: 'date',
                    setValueExpr: val => moment(val).format('YYYY-MM-DD')
                }
            ]
        }
    },

    methods: {
        goToStatement(item) {
            this.$router.replace({
                name: 'samples-statement',
                query: {
                    id: item.materialId
                }
            })
        },

        addDate () {
            this.date = moment(this.date).add(1, 'day').format('YYYY-MM-DD');
            return this.date
        },

        subDate() {
            this.date = moment(this.date).subtract(1, 'day').format('YYYY-MM-DD')
            return this.date
        },

        getLoanedMaterials(isFirst) {
            this.loading = true
            this.$store.dispatch('materialTransactions/fetchMaterialLoaned', { 
                materialId: this.materialId, 
                employeeIds: this.medicalRepIds, 
                FromDate: this.dateType === '1' ? moment(this.date).format('YYYY-MM-DD') : this.startDate, 
                ToDate: this.dateType === '1' ? moment(this.date).format('YYYY-MM-DD') : this.endDate, 
                EntityId: this.entityId
            }).then((data) => {
                this.items = data.data
            }).finally(() => {
                this.loading = false
            })
        },

        reset() {
            this.materialId = null
            this.medicalRepIds = [] 
            this.startDate = moment().startOf('month').format('YYYY-MM-DD')
            this.endDate = moment().endOf('month').format('YYYY-MM-DD')
            this.date = moment().format('YYYY-MM-DD')
            this.entityId = null
            this.type = null
        },
        getEntity () {
            this.loadingEntity = true
            this.$store.dispatch('entities/fetchAllBrief', { type: this.type }).finally(() => {
                this.loadingEntity = false
            })
        }
    },
    
    created() {
        this.loading = true
        Promise.all([
            this.getLoanedMaterials(true),  
            !this.materials.length ? this.$store.dispatch('materials/fetchAll') : null,
            !this.medicalreps.length ? this.$store.dispatch('medicalreps/fetchAll', true) : null,
            !this.entitiesBrief.length ? this.$store.dispatch('entities/fetchAllBrief', { type: entityType.doctor }) : null
        ]).then(() => {
            this.loading = false
        })
    }
}
</script>
