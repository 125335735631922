<template>
    <div>
        <v-row>
            <v-col class="pb-0">
                <h1 class="text-h6 primary--text text-capitalize">
                    {{$t('routes.products')}}
                </h1>
            </v-col>
            <v-col class="d-flex justify-end mt-1">            
                <div class="d-sm-flex">
                    <!-- activate dialog button -->
                    <v-btn
                        v-if="isSuperAdmin || permissionsUser.GenerateMaterials === roleName.action"
                        small
                        color="dark-yellow white--text"
                        class="mx-2"
                        @click="addProduct"
                    >
                        <v-icon class="me-2">
                            mdi-plus-circle-outline
                        </v-icon>
                        {{$t('Products.add-product')}}
                    </v-btn>

                    <v-spacer></v-spacer>

                    <v-btn v-if="isSuperAdmin || permissionsUser.GenerateMaterials !== roleName.nothing" :to="{ name: 'pharmaceutical-forms' }" color="dark-yellow white--text" small>
                        {{$t('Products.category')}}
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        
        <!-- dialogs is declared here to use as ref -->
        <v-dialog
            transition="dialog-transition"
            max-width="600"
            v-model="dialog"
        >

            <v-form @submit.prevent="submit" ref="form" :disabled="submitLoading">
                <v-card>
                    <div class="d-flex primary py-1">
                        <v-card-title
                            class="text-uppercase flex-grow-1 text-subtitle-1 justify-center pa-0 white--text"
                        >
                            {{ !materialId ? $t('Products.add-product') : $t('Products.edit-product') }}
                        </v-card-title>

                        <!-- close button -->
                        <v-btn icon @click="dialog = false; $refs.form.resetValidation()">
                            <v-icon color="white">
                                mdi-close-circle-outline
                            </v-icon>
                        </v-btn>
                    </div>
                    <v-card-text class="pt-2 px-3 pb-0">
                        <!-- dialog content -->
                        <v-row dense class="ma-0">
                            <!-- Product name -->
                            <v-col sm="6" md="4" cols="12">
                                <v-text-field
                                    v-model="materialData.name"
                                    :label="$t('inputs.product-name')"
                                    clearable
                                    :rules="rules.required"
                                    required
                                    outlined
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>

                            <!-- company name -->
                            <v-col sm="6" md="4" cols="12">
                                <v-autocomplete
                                    v-model="materialData.companyId"
                                    :items="companies"
                                    item-text="name"
                                    item-value="id"
                                    clearable
                                    required
                                    outlined
                                    dense
                                    :label="$t('inputs.company-name')"
                                    hide-details="auto"
                                />
                            </v-col>
                            <!-- Number -->
                            <v-col sm="6" md="4" cols="12">
                                <v-text-field
                                    v-model="materialData.number"
                                    :label="$t('inputs.number')"
                                    type="number"
                                    min="0"
                                    clearable
                                    outlined
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>

                            <!-- iscu -->
                            <v-col sm="6" md="4" cols="12" align-self="center">
                                <v-checkbox
                                	:label="$t('consumed')"
                                	v-model="materialData.consumed"
                                	hide-details
                                	class="mt-0"
                                >
                                </v-checkbox>
                            </v-col>

                            <v-col sm="6" md="4" cols="12">
                                <v-text-field 
                                    v-model.number="materialData.maxAmount"
                                    :label="$t('inputs.max-amount')"
                                    dense
                                    outlined
                                    hide-details
                                />
                            </v-col>

                            <v-col sm="6" md="4" cols="12">
                                <v-text-field 
                                    v-model.number="materialData.minAmount"
                                    :label="$t('inputs.min-amount')"
                                    dense
                                    outlined
                                    hide-details
                                />
                            </v-col>

                            <!-- english name -->
                            <v-col sm="6" md="4" cols="12">
                                <v-text-field
                                    v-model="materialData.englishName"
                                    :label="$t('inputs.english-name')"
                                    clearable
                                    required
                                    outlined
                                    dense
                                    hide-details="auto"
                                />
                            </v-col>

                            <!-- code -->
                            <v-col sm="6" md="4" cols="12">
                                <v-text-field
                                    v-model="materialData.code"
                                    :label="$t('inputs.code')"
                                    clearable
                                    required
                                    outlined
                                    dense
                                    hide-details="auto"
                                />
                            </v-col>

                            <!-- source -->
                            <v-col sm="6" md="4" cols="12">
                                <v-text-field
                                    v-model="materialData.source"
                                    :label="$t('inputs.source')"
                                    clearable
                                    required
                                    outlined
                                    dense
                                    hide-details="auto"
                                />
                            </v-col>

                            <!-- pharmaceutical form -->
                            <v-col sm="6" md="4" cols="12">
                                <v-autocomplete
                                    v-model="materialData.materialFormId"
                                    :label="$t('inputs.category')"
                                    clearable
                                    :items="pharmaceuticalForm"
                                    item-text="name"
                                    item-value="id"
                                    required
                                    outlined
                                    dense
                                    hide-details="auto"
                                ></v-autocomplete>
                            </v-col>

                            <!-- Volume -->
                            <v-col v-if="0" md="4" sm="6" cols="12">
                                <v-text-field
                                    v-model="materialData.dose"
                                    :label="$t('inputs.volume')"
                                    clearable
                                    outlined
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>

                            <!-- Pills per box -->
                            <v-col v-if="0" md="4" sm="6" cols="12">
                                <v-text-field
                                    v-model="materialData.pillsPerBox"
                                    :label="$t('inputs.pills-per-box')"
                                    clearable
                                    outlined
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>

                            <!-- Price -->
                            <v-col sm="6" md="4" cols="12">
                                <v-text-field
                                    v-model.number="materialData.price"
                                    :label="$t('inputs.price')"
                                    type="number"
                                    min="0"
                                    step="50"
                                    clearable
                                    outlined
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>

                            <!-- Price -->
                            <v-col sm="6" md="4" cols="12">
                                <v-text-field
                                    v-model.number="materialData.minimumPrice"
                                    :label="$t('inputs.minimum-price')"
                                    type="number"
                                    min="0"
                                    step="50"
                                    clearable
                                    outlined
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>

                            <!-- product file  -->
                            <v-col sm="12" md="12" cols="12">
                                <v-text-field
                                    v-model="materialData.leafletFilePath"
                                    :label="$t('inputs.leaflet-url')"
                                    class="align-self-center"
                                    :prepend-icon="materialData.leafletFilePath?'mdi-link-box-variant':'mdi-link-box-variant-outline'"
                                    required
                                    outlined
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>

                            <!-- Strength -->
                            <v-col v-if="0" sm="3" cols="12">
                                <v-text-field
                                    v-model="materialData.strength"
                                    :label="$t('inputs.strength')"
                                    clearable
                                    outlined
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>

                            <!-- description & image -->
                            <v-col cols="12">
                                <v-row dense class="ma-0">
                                    <!-- image -->
                                    <v-col v-if="0" sm="4" cols="12">
                                        <div class="d-flex justify-center pb-0">
                                            <div class="personal-image">
                                                <v-img
                                                    height="90"
                                                    max-height="90"
                                                    width="150"
                                                    max-width="150"
                                                    class="rounded"
                                                    :src="previewPhoto || require('@/assets/placeholderProduct.png')"
                                                    :gradient="productPhoto ? '#eee2, #eee7, #eee2' : null"
                                                ></v-img>
                                                
                                                <div class="personal-image__actions">
                                                    <v-btn
                                                        v-if="productPhoto"
                                                        icon
                                                        width="30"
                                                        height="30"
                                                        min-width="0"
                                                        color="red lighten-1"
                                                        @click="previewPhoto = null; productPhoto = null"
                                                    >
                                                        <v-icon> mdi-delete </v-icon>
                                                    </v-btn>

                                                    <v-file-input
                                                        v-model="productPhoto"
                                                        class="ma-0 pa-0"
                                                        accept="image/*"
                                                        :prepend-icon="productPhoto ? 'mdi-image' : 'mdi-plus-circle'"
                                                        hide-input
                                                        @change="onImageChange"
                                                    ></v-file-input>
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                    
                                    <!-- description -->
                                    <v-col cols="12" class="px-0">
                                        <v-textarea
                                            v-model="materialData.description"
                                            label="Description"
                                            rows="3"
                                            no-resize
                                            auto-grow
                                            outlined
                                            dense
                                            hide-details="auto"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-end pt-0">
                        <v-btn
                            type="submit"
                            :loading="submitLoading"
                            :disabled="submitLoading"
                            color="success"
                            text
                        >
                            {{$t('save')}}
                        </v-btn>
                        <v-btn
                            @click="dialog = false"
                            text
                        >
                            {{$t('cancel')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        
        <!-- delete dialog declaered here to use it as ref -->
        <delete-dialog
        	ref="deleteDialog"
            @on-delete="filter"
        	:success-message="$t('data-is-deleted')"
        >
            <template v-slot:default>
                <p class="mb-2">
                    {{$t('are-you-sure-you-want-to')}} <span class="red--text">{{$t('delete')}}</span> {{$t('the-product')}}
                </p>
            </template>
        </delete-dialog>

        <!-- filter -->
        <v-form @submit.prevent="isFiltered = true; filter();" :disabled="loading">
            <v-row dense class="my-1" align="center">
                <!-- pharmaceutical form -->
                <v-col md="2" sm="6" cols="12">
                    <v-autocomplete
                        v-model="materialFormId"
                        :items="materialForms"
                        item-text="name"
                        item-value="id"
                        :label="$t('inputs.category')"
                        dense
                        clearable
                        hide-details
                        :disabled="loading"
                        outlined
                    ></v-autocomplete>
                </v-col>

                <!-- company name -->
                <v-col sm="6" md="2" cols="12">
                    <v-autocomplete
                        v-model="companyId"
                        :label="$t('inputs.company-name')"
                        :items="companies"
                        item-text="name"
                        item-value="id"
                        clearable
                        required
                        outlined
                        :disabled="loading"
                        dense
                        hide-details
                    />
                </v-col>

                <!-- code -->
                <v-col v-if="0" sm="6" md="2" cols="12">
                    <v-text-field
                        v-model="code"
                        :label="$t('inputs.code')"
                        clearable
                        :rules="rules.required"
                        required
                        outlined
                        dense
                        hide-details
                    />
                </v-col>

                <!-- product name -->
                <v-col md="2" sm="6" cols="12">
                    <v-autocomplete
                        v-model="materialName"
                        :items="loading ? [] : materials"
                        :item-text="getFullMaterialName"
                        item-value="name"
                        :label="$t('inputs.product-name')"
                        dense
                        clearable
                        hide-details
                        :disabled="loading"
                        outlined
                    ></v-autocomplete>
                </v-col>

                <v-col md="2" sm="6" cols="12">
                    <v-text-field 
                        v-model="textSearch" 
                        dense
                        hide-details
                        :disabled="loading"
                        outlined 
                        append-icon="mdi-magnify"
                        :label="$t('inputs.search')"
                    />
                </v-col>
                <!-- from price -->
                <v-col md="1" sm="6" cols="12">
                    <v-text-field 
                        v-model.number="fromPrice"
                        :label="$t('inputs.from-price')"
                        type="number"
                        dense
                        outlined
                        hide-details
                        :disabled="loading"
                    />
                </v-col>

                <!-- to price -->
                <v-col md="1" sm="6" cols="12">
                    <v-text-field 
                        v-model.number="toPrice"
                        :label="$t('inputs.to-price')"
                        type="number"
                        dense
                        outlined
                        hide-details
                        :disabled="loading"
                    />
                </v-col>
            
                <v-col> 
                    <v-btn-toggle
                        v-model.number="sort"
                        mandatory
                        dense
                        class="mb-1"
                        color="primary"
                        @change="isFiltered = true; filter();"
                        :disabled="loading"
                    >
                    <v-btn :disabled="loading"> {{$t('inputs.sort-by-price')}} </v-btn>
                    <v-btn :disabled="loading">{{ $t('inputs.sort-by-minimum-price') }}</v-btn>
                </v-btn-toggle>
                </v-col>

                <v-spacer />
            
                <v-col class="d-flex justify-start justify-md-end">
                    <v-btn
                        type="submit"
                        color="success"
                        class="px-sm-4 mx-2"
                        :loading="loading"
                        :disabled="isAllFieldsEmpty || loading"
                        small
                    >
                        <v-icon> mdi-magnify </v-icon>
                        {{$t('search')}}
                    </v-btn>
                    <v-btn
                        color="alert-color white--text"
                        class="px-sm-8"
                        small
                        :disabled="!isFiltered || loading"
                        @click="clearFilter(); filter()"
                    >
                        {{$t('reset')}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

        <!-- table -->
        <bee-handy-table
            :headers="headers"
            :items="materialsFiltered"
            fixed-header
            :items-per-page="20"
            pagination-on-scroll
            :height="$vuetify.breakpoint.height - 160"
            zebra
            dense
            :loading="loading"
            :data-table-props="{
                disableSort: true
            }"
            @click:row="get"
        >
            <!-- action column will appaer only for admin -->
            <template v-slot:item.action="{item}">
                <div class="d-flex justify-center">
                    <tooltip :text="$t('Products.edit-product')" top>
                        <v-btn icon small @click="isEdit = true; editProduct(item.id)" color="edit-color">
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>
            <template v-slot:item.consumed="{item}">
                {{ item.consumed ? $t('consumed') : $t('not-consumed') }}
            </template>
            
        </bee-handy-table>
    </div>    
</template>

<script>
import Tooltip from '@/components/Tooltip'
import DeleteDialog from '@/components/DeleteDialog'
import { mapState, mapGetters } from 'vuex'
import { getMaterialName } from '@/helpers/functions'
import rules from '@/validation rules'
import { roleName } from '@/helpers/enums'

export default {
    name: 'Products',

    watch: {
        dialog (val) {
            if (val === false) {
                this.isEdit = false
            }
        }
    },
    
    components:{
        Tooltip,
        DeleteDialog
    },

    data(){
        return {
            dialog: false,
            textSearch: null,
            roleName,
            isEdit: false,
            // filter
            materialName: null,
            materialFormId: null,
            isFiltered: false,
            materialsFiltered: [],
            fromPrice: null,
            toPrice: null,
            sort: 0,

            // helpers
            loading: false,
            submitLoading: false,
            rules,

            // product dialog
            materialId: null,

            productPhoto: null,
            previewPhoto: null,

            // data
            materialData:{
                name: null,
                materialFormId: null,
                dose: null,
                strength: null,
                pillsPerBox: null,
                number: null,
                price: null,
                description: null,
                leafletFilePath: null,
                // specialityIds: []
                companyId: null,
                code: null,
                englishName: null,
                consumed: true,
                maxAmount: 0,
                minAmount: 0,
                minimumPrice: null
            },
            companyId: null,
            code: null
        }
    },

    computed:{
        isAllFieldsEmpty(){
            return !this.materialName && !this.materialFormId && !this.companyId && !this.fromPrice && !this.toPrice && !this.sort && !this.textSearch;
        },

        ...mapState({
            materials: state => state.materials.materials,
            materialForms: state => state.materialForms.materialForms,
            pharmaceuticalForm: state => state.materialForms.materialForms,
            specialties: state => state.specialties.specialties,
            permissionsUser: state => state.auth.youPermissions,
            isSuperAdmin: state => state.auth.isSuperAdmin,
            companies: state => state.companies.companies
        }),

        ...mapGetters({
            getMaterialFormById: 'materialForms/getMaterialFormById',
            isAdmin: 'auth/isAdmin',
            getMaterialById: 'materials/getMaterialById',
            getSpecialtyById: 'specialties/getSpecialtyById',
            getCompanyById: 'companies/getCompanyById'
        }),

        headers() {
            const headers = [
                { 
                    text: this.$t('headers.product-name'), 
                    align: 'start', 
                    value: 'name',
                    class: 'text-body-2 font-weight-bold',
                    columnClass: 'primary--text px-2',
                },
                { 
                    text: this.$t('headers.company-name'), 
                    value: 'companyId', 
                    setValueExpr: val => this.getCompanyById(val)?.name,
                    align: 'start', 
                    class: 'text-body-2 font-weight-bold',
                    columnClass: 'px-2',
                },

                { 
                    text: 'نوع المادة', 
                    value: 'consumed', 
                    name: 'consumed',
                    setValueExpr: val => val === true ? val : false,
                    align: 'start', 
                    class: 'text-body-2 font-weight-bold',
                    columnClass: 'px-2',
                },
                {
                    text: this.$t('headers.category'), 
                    value: 'materialFormId', 
                    setValueExpr: val => val ? this.getMaterialFormById(val)?.name : '-',
                    align: 'start', 
                    noNotAvailableText: true,
                    class: 'text-body-2 font-weight-bold',
                    columnClass: 'px-2',
                },
                {
                    text: this.$t('headers.max-amount'),
                    name: 'max-amount',
                    value: 'maxAmount'
                },
                {
                    text: this.$t('headers.min-amount'),
                    name: 'min-amount',
                    value: 'minAmount'
                },
                {
                    text: this.$t('headers.english-name'), 
                    value: 'englishName', 
                    // setValueExpr: val => val !== null ? val : '-',
                    align: 'center', 
                    class: 'text-body-2 font-weight-bold',
                    columnClass: 'px-2',
                },
                { 
                    text: this.$t('headers.source'), 
                    value: 'source', 
                    align: 'start',
                    class: 'text-body-2 font-weight-bold',
                    columnClass: 'px-2',
                },
                { 
                    text: this.$t('headers.code'), 
                    value: 'code', 
                    // setValueExpr: val => val !== null ? val : '-',
                    align: 'center', 
                    class: 'text-body-2 font-weight-bold',
                    columnClass: 'px-2',
                },
                { 
                    text: this.$t('headers.price'), 
                    value: 'price', 
                    setValueExpr: val => val !== null ? val : '-',
                    align: 'start', 
                    class: 'text-body-2 font-weight-bold',
                    columnClass: 'px-2',
                    noNotAvailableText: true,
                },
                { 
                    text: this.$t('headers.description'), 
                    value: 'description', 
                    setValueExpr: val => val !== null ? val : '-',
                    align: 'start', 
                    class: 'text-body-2 font-weight-bold',
                    columnClass: 'px-2',
                    noNotAvailableText: true,
                }
            ];

            if (this.isSuperAdmin || this.permissionsUser.MaterialTransactions === roleName.action){
                headers.push({ text: this.$t('headers.actions'), name: 'action', value: 'action', align: 'center', cellClass:'width', sortable: false })
            }

            return headers;
        },

        iconSpecialty() {
            if (this.selectedAllSpecialties) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },
    },

    methods: {
        get(item) {
            if (this.isEdit === false) {
                this.$router.replace({
                    name: 'samples-statement',
                    query: {
                        id: item.id
                    }
                })
            }
        },
        
        getFullMaterialName(material) {
            return getMaterialName(material, this.getMaterialFormById(material.materialFormId).name)
        },

        // filter
        clearFilter(){
            this.materialName = null;
            this.materialFormId = null;
            this.isFiltered = false;
            this.companyId = null
            this.textSearch = null
            this.fromPrice = null
            this.toPrice = null
            this.sort = 0
        },
        
        filter(isFirstLoad = false){
            this.loading = true;
            return this.$store.dispatch('materials/fetchByFilter', {
                name: this.materialName,
                materialFormId: this.materialFormId,
                companyId: this.companyId,
                fromPrice: this.fromPrice, 
                toPrice: this.toPrice, 
                orderByPrice: (this.sort === 0), 
                orderByMinimumPrice: (this.sort === 1),
                NameOrDescription: this.textSearch
            }).then((data) => {
                this.materialsFiltered = data;
            })
            .finally(() => {
                this.loading = false
                // if (!isFirstLoad) this.loading = false;
            })
        },

        // product dialog
        resetInputs(){
            if (this.$refs.form) this.$refs.form.resetValidation();
            this.previewPhoto = null;
            this.productPhoto = null;
            this.materialId = null;

            this.materialData.name = null;
            this.materialData.materialFormId = null;
            // this.materialData.specialityIds = null;
            this.materialData.dose = null;
            this.materialData.strength = null;
            this.materialData.pillsPerBox = null;
            this.materialData.number = null;
            this.materialData.price = null;
            this.materialData.minimumPrice = null;
            this.materialData.description = null;
            this.materialData.leafletFilePath = null;
            this.materialData.englishName = null;
            this.materialData.companyId = null;
            this.materialData.code = null;
            this.materialData.source = null
            this.materialData.consumed = true
            this.materialData.minAmount = 0
            this.materialData.maxAmount = 0
        },

        addProduct(){
            this.resetInputs();
            this.dialog = true;
        },

        editProduct(materialId) {
            this.resetInputs();
            this.materialId = materialId;
            this.dialog = true;
            var material = this.getMaterialById(materialId);
            
            this.materialData.name = material.name;
            this.materialData.materialFormId = material.materialFormId;
            // this.materialData.specialityIds = material.specialityIds;
            this.materialData.dose = material.dose;
            this.materialData.strength = material.strength;
            this.materialData.pillsPerBox = material.pillsPerBox;
            this.materialData.number = material.number;
            this.materialData.price = material.price;
            this.materialData.minimumPrice = material.minimumPrice
            this.materialData.description = material.description;
            this.materialData.leafletFilePath = material.leafletFilePath;
            this.materialData.englishName = material.englishName;
            this.materialData.companyId = material.companyId;
            this.materialData.code = material.code;
            this.materialData.source = material.source;
            this.materialData.consumed = material.consumed
            this.materialData.maxAmount = material.maxAmount
            this.materialData.minAmount = material.minAmount
        },

        onImageChange() {
            if (this.productPhoto) {
                const reader = new FileReader()
                reader.readAsDataURL(this.productPhoto)
                reader.onload = e => { this.previewPhoto = e.target.result }
            } else {
                this.productPhoto = null
                this.previewPhoto = null
            }
        },

        submit(){
            if (this.$refs.form.validate()){
                this.submitLoading = true;
                const actionName = !this.materialId ? 'create' : 'update';
                this.$store.dispatch(`materials/${actionName}`, {
                    id: this.materialId,
                    data: this.materialData
                })
                .then(() => {
                    const message = (!this.materialId) ? this.$t('Products.product-is-added') : this.$t('Products.product-is-edited')
                    this.$eventBus.$emit('show-snackbar', message, 'info');
                    this.$store.dispatch('materials/fetchAll');
                    this.filter();
                    this.dialog = false;
                    this.isEdit = false
                })
                .finally(() => {
                    this.submitLoading = false;
                })
            }
        }
    },

    created(){
        this.loading = true;
        Promise.all([
            !this.materials.length ? this.$store.dispatch('materials/fetchAll') : null,
            !this.materialForms.length ? this.$store.dispatch('materialForms/fetchAll') : null,
            this.$store.dispatch('specialties/fetchAll'),
            this.$store.dispatch('companies/fetchAll'),
            this.filter(true)
        ]).finally(() => {
            this.loading = false;
        })
    },

    metaInfo: {
        title: 'Products',
        titleTemplate: '%s | Octopharma'
    }
}
</script>