<template>
	<v-app>
        <navbar v-if="authenticated && $vuetify.breakpoint.mdAndUp" :drawer="drawer" />
        <navigation-drawer v-if="authenticated && $vuetify.breakpoint.smAndDown" :drawer="drawer" />
        <v-main>
            <v-container fluid class="pt-0">
                <loader v-if="!authenticated && $route.name !== 'login'" height="95vh"/>
                <!--
                    if it is in any page (except login page), 'authenticated' value will control in route rendering
                    else (for login page) will always be rendered

                    this for rendering page in the same time of rendering navigation-drawer and navbar
                -->
                <router-view v-if="$route.name !== 'login'? authenticated : true"/>
            </v-container>
        </v-main>

        <!-- snackbar -->
        <v-snackbar
            v-model="snackbar"
            app
            transition="slide-y-reverse-transition"
            :color="
                (state === 'info' ? 'primary': '') +
                (state === 'warn' ? 'warning': '') +
                (state === 'error' ? 'red lighten-1': '') + ' white--text'
            "
        >
            {{ message }}

            <template v-slot:action>
				<v-btn
					color="white"
					icon
					@click="snackbar = false"
				>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-row v-if="authenticated">
            <v-col class="tt">
                <v-btn
                    @click="dialogAddMaterials = true"
                    color="primary"
                    class="btn-f rounded-circle"
                    small
                    width="40px"
                    height="47px"
                >
                    <!-- <v-icon>
                        mdi-plus
                    </v-icon> -->
                    <v-img 
                        src="@/assets/sidebar-icons/blood-sample-white.png" 
                        contain
                        max-width="22"
                        width="22"
                        min-width="22" 
                    /> 
                </v-btn>
            </v-col>
        </v-row>

        <!--  -->
        <!-- add new materials -->
		<v-dialog
			v-model="dialogAddMaterials"
            max-width="600"    
        >
            <!-- <template v-slot:default="dialog"> -->
			<v-form @submit.prevent="submit" ref="form" :disabled="submitLoading">
				<v-card>
					<div class="d-flex primary py-1">
						<v-card-title
							class="text-uppercase flex-grow-1 text-subtitle-1 justify-center pa-0 white--text"
						>
							{{ $t('Products.add-product') }}
						</v-card-title>

						<!-- close button -->
						<v-btn icon @click="dialogAddMaterials = false; $refs.form.resetValidation()">
							<v-icon color="white">
								mdi-close-circle-outline
							</v-icon>
						</v-btn>
					</div>
					<v-card-text class="pt-2 px-3 pb-0">
						<!-- dialog content -->
						<v-row dense class="ma-0">
							<!-- Product name -->
							<v-col sm="6" md="4" cols="12">
								<v-text-field
									v-model="materialData.name"
									:label="$t('inputs.product-name')"
									clearable
									:rules="rules.required"
									required
									outlined
									dense
									hide-details="auto"
								></v-text-field>
							</v-col>

							<!-- company name -->
							<v-col sm="6" md="4" cols="12">
								<v-autocomplete
									v-model="materialData.companyId"
									:items="companies"
									item-text="name"
									item-value="id"
									clearable
									required
									outlined
									dense
									:label="$t('inputs.company-name')"
									hide-details="auto"
								/>
							</v-col>
							<!-- Number -->
							<v-col sm="6" md="4" cols="12">
								<v-text-field
									v-model="materialData.number"
									:label="$t('inputs.number')"
									type="number"
									min="0"
									clearable
									outlined
									dense
									hide-details="auto"
								></v-text-field>
							</v-col>

							<!-- iscu -->
							<v-col sm="6" md="4" cols="12" align-self="center">
								<v-checkbox label="مستهلكة" v-model="materialData.consumed" hide-details class="mt-0"></v-checkbox>
							</v-col>

							<v-col sm="6" md="4" cols="12">
								<v-text-field 
									v-model.number="materialData.maxAmount"
									:label="$t('inputs.max-amount')"
									dense
									outlined
									hide-details
								/>
							</v-col>

							<v-col sm="6" md="4" cols="12">
								<v-text-field 
									v-model.number="materialData.minAmount"
									:label="$t('inputs.min-amount')"
									dense
									outlined
									hide-details
								/>
							</v-col>

							<!-- english name -->
							<v-col sm="6" md="4" cols="12">
								<v-text-field
									v-model="materialData.englishName"
									:label="$t('inputs.english-name')"
									clearable
									required
									outlined
									dense
									hide-details="auto"
								/>
							</v-col>

							<!-- code -->
							<v-col sm="6" md="4" cols="12">
								<v-text-field
									v-model="materialData.code"
									:label="$t('inputs.code')"
									clearable
									required
									outlined
									dense
									hide-details="auto"
								/>
							</v-col>

							<!-- source -->
							<v-col sm="6" md="4" cols="12">
								<v-text-field
									v-model="materialData.source"
									:label="$t('inputs.source')"
									clearable
									required
									outlined
									dense
									hide-details="auto"
								/>
							</v-col>

							<!-- pharmaceutical form -->
							<v-col sm="6" md="4" cols="12">
								<v-autocomplete
									v-model="materialData.materialFormId"
									:label="$t('inputs.category')"
									clearable
									:items="pharmaceuticalForm"
									item-text="name"
									item-value="id"
									required
									outlined
									dense
									hide-details="auto"
								></v-autocomplete>
							</v-col>

							<!-- Volume -->
							<v-col v-if="0" md="4" sm="6" cols="12">
								<v-text-field
									v-model="materialData.dose"
									:label="$t('inputs.volume')"
									clearable
									outlined
									dense
									hide-details="auto"
								></v-text-field>
							</v-col>

							<!-- Pills per box -->
							<v-col v-if="0" md="4" sm="6" cols="12">
								<v-text-field
									v-model="materialData.pillsPerBox"
									:label="$t('inputs.pills-per-box')"
									clearable
									outlined
									dense
									hide-details="auto"
								></v-text-field>
							</v-col>

							<!-- product file  -->
							<v-col sm="6" md="4" cols="12">
								<v-text-field
									v-model="materialData.leafletFilePath"
									:label="$t('inputs.leaflet-url')"
									class="align-self-center"
									:prepend-icon="materialData.leafletFilePath?'mdi-link-box-variant':'mdi-link-box-variant-outline'"
									required
									outlined
									dense
									hide-details="auto"
								></v-text-field>
							</v-col>

							<!-- Strength -->
							<v-col v-if="0" sm="3" cols="12">
								<v-text-field
									v-model="materialData.strength"
									:label="$t('inputs.strength')"
									clearable
									outlined
									dense
									hide-details="auto"
								></v-text-field>
							</v-col>

							<!-- Price -->
							<v-col sm="6" md="4" cols="12">
								<v-text-field
									v-model.number="materialData.price"
									:label="$t('inputs.price')"
									type="number"
									min="0"
									step="50"
									clearable
									outlined
									dense
									hide-details="auto"
								></v-text-field>
							</v-col>

							<!-- description & image -->
							<v-col cols="12">
								<v-row dense class="ma-0">
									<!-- image -->
									<v-col v-if="0" sm="4" cols="12">
										<div class="d-flex justify-center pb-0">
											<div class="personal-image">
												<v-img
													height="90"
													max-height="90"
													width="150"
													max-width="150"
													class="rounded"
													:src="previewPhoto || require('@/assets/placeholderProduct.png')"
													:gradient="productPhoto ? '#eee2, #eee7, #eee2' : null"
												></v-img>
												
												<div class="personal-image__actions">
													<v-btn
														v-if="productPhoto"
														icon
														width="30"
														height="30"
														min-width="0"
														color="red lighten-1"
														@click="previewPhoto = null; productPhoto = null"
													>
														<v-icon> mdi-delete </v-icon>
													</v-btn>

													<v-file-input
														v-model="productPhoto"
														class="ma-0 pa-0"
														accept="image/*"
														:prepend-icon="productPhoto ? 'mdi-image' : 'mdi-plus-circle'"
														hide-input
														@change="onImageChange"
													></v-file-input>
												</div>
											</div>
										</div>
									</v-col>
									
									<!-- description -->
									<v-col cols="12" class="px-0">
										<v-textarea
											v-model="materialData.description"
											label="Description"
											rows="3"
											no-resize
											auto-grow
											outlined
											dense
											hide-details="auto"
										></v-textarea>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions class="justify-end pt-0">
						<v-btn
							type="submit"
							:loading="submitLoading"
							:disabled="submitLoading"
							color="success"
							text
						>
							{{$t('save')}}
						</v-btn>
						<v-btn
							@click="dialogAddMaterials = false"
							text
						>
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
            <!-- </template> -->
        </v-dialog>
	</v-app>
</template>

<script>
import Loader from './components/Loader'
import NavigationDrawer from './components/NavigationDrawer'
import { mapState } from 'vuex'
import Navbar from './components/Navbar.vue'
import rules from '@/validation rules'

export default {
	name: 'App',

	components:{
        Loader,
        NavigationDrawer,
        Navbar
	},

	data: () => ({
        rules,
        isLogin: false,
        drawer: false,

        snackbar: false,
        state: null,
        message: null,
        dialogAddMaterials: false,
        materialData:{
            name: null,
            materialFormId: null,
            dose: null,
            strength: null,
            pillsPerBox: null,
            number: null,
            price: null,
            description: null,
            leafletFilePath: null,
            // specialityIds: []
            companyId: null,
            code: null,
            englishName: null,
            consumed: true,
            maxAmount: 0,
            minAmount: 0
        },
        submitLoading: false
    }),

    computed:{
        ...mapState({
            authenticated: state => state.auth.authenticated,
			companies: state => state.companies.companies,
			pharmaceuticalForm: state => state.materialForms.materialForms
        }),
    },

    methods: {
        submit(){
			if (this.$refs.form.validate()){
				this.submitLoading = true;
				this.$store.dispatch('materials/create', {
					data: this.materialData
				})
				.then(() => {
					const message = this.$t('Products.product-is-added')
					this.dialogAddMaterials = false;
				})
				.finally(() => {
					this.submitLoading = false;
				})
			}
		}
    },
    
    created() {
		this.$store.dispatch('companies/fetchAll')
		this.$store.dispatch('materialForms/fetchAll')
        this.$eventBus.$on('show-snackbar', (message, state = 'info') => {
            this.message = message;
            this.state = state;
            this.snackbar = true;
        })
    }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;600;700&display=swap');
@import "scss/main.scss";

.pace {
    -webkit-pointer-events: none;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
  
.pace-inactive {
	display: none;
}
  
.pace .pace-progress {
    background: $color-primary;
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 3px;
}
  
.pace .pace-progress-inner {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px $color-primary, 0 0 5px rgb(126, 123, 123);
    opacity: 1.0;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -moz-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    -o-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
}
  
.pace .pace-activity {
    display: block;
    position: fixed;
    z-index: 2000;
    top: 15px;
    right: 15px;
    width: 14px;
    height: 14px;
    border: solid 2px transparent;
    border-top-color: $color-primary;
    border-left-color: $color-primary;
    border-radius: 10px;
    -webkit-animation: pace-spinner 400ms linear infinite;
    -moz-animation: pace-spinner 400ms linear infinite;
    -ms-animation: pace-spinner 400ms linear infinite;
    -o-animation: pace-spinner 400ms linear infinite;
    animation: pace-spinner 400ms linear infinite;
}
  
@-webkit-keyframes pace-spinner {
	0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@-moz-keyframes pace-spinner {
	0% { -moz-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}
@-o-keyframes pace-spinner {
	0% { -o-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}
@-ms-keyframes pace-spinner {
	0% { -ms-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -ms-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes pace-spinner {
	0% { transform: rotate(0deg); transform: rotate(0deg); }
	100% { transform: rotate(360deg); transform: rotate(360deg); }
}

/* ============================ */
*{
    font-family: 'Cairo', sans-serif;
    // font-size: .9rem;
}

// remove arrows from input number type
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.fixed-width{
    // fixing date column width
    &-date{
        width: 110px;
        min-width: 110px;
    }

    // fixing material column width
    &-material{
        width: 200px;
        min-width: 200px;
    }

    // fixing note column width
    &-notes{
        width: 200px;
        min-width: 200px;
    }
}

.font-wight{
    &-500 {
        font-weight: 500;
    }
    &-600 {
        font-weight: 600;
    }
    &-700 {
        font-weight: 700;
    }
}
.font-size{
    &-1 {
        font-size: .94rem !important;
    }
    &-2 {
        font-size: 1.2rem !important;
    }
    &-3 {
        font-size: 1.45rem !important;
    }
}

@media print {
    .v-navigation-drawer, .v-app-bar, .v-btn {
        display: none;
        
    }
    .v-main {
        padding: 0 !important;
    }
}

.background-title-card {
    background-color: #217073;
}
.red-light-one-bg {
    background-color: #EF5350
}
.w-100 {
    width: 100%;
}

*::-webkit-scrollbar{
    height: 5px;
    width: 5px;
	background-color: #f8f8f8;
}
*::-webkit-scrollbar-thumb{
    background-color: #888;
    border-radius: 10px;
}

#app {
    .v-input {
		[dir=rtl] & .v-messages__message {
			font-size: 12px;
		}
	}
	.v-input:not(.v-input--radio-group) .v-label:not(.v-label--active) {
		transform: translateY(-4px);
	}
	.v-text-field:not(.v-textarea) .v-input__control .v-input__slot {
		min-height: auto;
		max-height: 32px;
	}
    .v-text-field__suffix {
        margin-top: 0px;
    }
    .v-file-input .v-file-input__text {
        padding-top: 0;
    }
	.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner, .v-input__prepend-outer {
		margin-top: 4px;
	}
	.v-text-field.v-text-field--enclosed .v-text-field__details {
		margin-bottom: 0;
	}
	.v-input--dense > .v-input__control > .v-input__slot {
		margin-bottom: 2px;
	}
    .ep-container linearGradient {
        transform: rotate(-10deg);
    }
}

.personal-image {
    position: relative;

    &__actions {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 38%;
        right: 38%;
        left: 38%;
    }
}

.tt .btn-f {
    position: fixed; 
    bottom: 20px; 
    left: 15px
}
</style>