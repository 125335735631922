export default {
    home: 'الصفحة الرئيسية',
    'scientific-office': 'المكتب العلمي',
    visits: 'الزيارات',
    'med-rep-plan': 'خطة مندوب',
    'day-off-requests': 'طلبات الإجازة',
    'day-off-request': 'طلب إجازة',
    reports: 'تقارير',
    'med-rep-mark': 'علامات مندوب',
    exams: 'امتحانات',
    salaries: 'الرواتب',
    storehouse: 'المستودع',
    'delivery-materials': 'تسليم مواد',
    'items-journal': 'يومية مواد',
    statement: 'كشف',
    management: 'إدارة',
    staff: 'طاقم العمل',
    customers: 'العملاء',
    'managing-roles': 'إدارة الأدوار',
    'external-entities': 'الجهات الخارجية',
    products: 'المنتجات',
    'regions-management': 'إدارة المناطق',
    'my-profile': 'الملف الشخصي',
    'my-visits': 'زياراتي',
    'my-report': 'تقريري',
    'my-plan': 'خطتي',
    companies: 'الشركات',
    logout: 'تسجيل الخروج',
    materials: 'المواد',
    'loaned-materials': 'المواد المعارة',

    'add-day-off-request': 'إضافة طلب إجازة',
    'products-statement': 'كشف مواد',
    'minimal-interface': 'مواد على وشك النفاذ'
}